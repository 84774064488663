import styled from 'styled-components';
import { Col, Row } from 'reactstrap';

export const RowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${props => props.justify}
;
`
export const ColWrapper = styled.div`
    display:flex;
    flex-direction: column;
    align-items:left;
`

export const BleedWrapper = styled(Row)`
    flex-wrap: nowrap;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    margin-top: 0px;
   
    div[class*='col-']{
        scroll-snap-align: start;
        scroll-snap-stop: always; 
       
    }

    &::-webkit-scrollbar {
    display: none;
    }
`
