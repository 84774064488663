import React, {useState, useEffect, useRef} from 'react'
import styled from 'styled-components';
import {Container} from 'reactstrap'
import { WhiteLink, RedLink } from '../../styled/Links.styled';

import { useLocation, useHistory } from 'react-router-dom';

import { API_ENDPOINT } from '../../../constants';

import searchIco from '../../../assets/top_page/search.svg';

import {ImSearch} from 'react-icons/im';

import {IoMdClose} from 'react-icons/io';


import './Navigation.css';

export const NavigationStyled = styled.div`
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 13px 0px 15px 0px;
    overflow-x: scroll;
    &::-webkit-scrollbar {
    display: none;
}
    @media (max-width: 530px) {
       
    }
`

export default function Navigation() {
    const location = useLocation();
    const history = useHistory();
    // console.log(location);
    const [categories, setCategories] = useState([]);

    const searchRef = useRef();

    const [showSearch, setShowSearch] = useState(false);

    const toggleSearch = () => {
        setShowSearch(prev => !prev);
    }

    useEffect(() => {
        setCategories([]);
        fetch(API_ENDPOINT + '/getAllCategories', {method: 'GET'})
        .then(res => res.json())
        .then(data => setCategories(data))
    }, []);

    const handleSubmitSearch = (e) => {
        e.preventDefault();
        const search = searchRef.current.value;
        if (!search || search === '') {
            return;
        }
        history.push(`/pretraga/${search}`);
        setShowSearch(false);
    }

    

    // console.log(categories);
    return (
        <div>
            <Container>
                <NavigationStyled>
                    {location.pathname === '/' ? <RedLink to='/'>Početna</RedLink> : <WhiteLink to='/'>Početna</WhiteLink>}
                    {location.pathname === '/najnovije-vijesti' ? <RedLink to='/najnovije-vijesti'>Najnovije</RedLink> : <WhiteLink to='/najnovije-vijesti'>Najnovije</WhiteLink>}
                    {categories.length && categories.map(cat => {
                        if (location.pathname.includes(cat.alias)) {
                            return <RedLink key={cat._id} to={`/kategorije/${cat.alias}`}>{cat.categoryName}</RedLink>
                        } else {
                            return <WhiteLink key={cat._id} to={`/kategorije/${cat.alias}`}>{cat.categoryName}</WhiteLink>
                        }
                    })}
                    {/* <RedLink to="/">Home</RedLink>
                    <WhiteLink >Latest News</WhiteLink>
                    <WhiteLink>Sport</WhiteLink>
                    <WhiteLink to="/magazine">Magazine</WhiteLink>
                    <WhiteLink>Fun</WhiteLink>
                    <WhiteLink>Celebrity</WhiteLink>
                    <WhiteLink>Culture</WhiteLink>
                    <WhiteLink>Video</WhiteLink> */}
                {showSearch && <div className='searchDiv'>
                        <form onSubmit={handleSubmitSearch}>
                            <input type='text' required ref={searchRef} />
                        <button type='submit'><ImSearch fill='#fff' /></button>
                        </form>
                        {/* <p>Ili pritisnite Enter</p> */}
                    </div>}
                    {showSearch ? <IoMdClose title='Zatvori pretragu' onClick={() => setShowSearch(false)} style={{cursor: 'pointer', width: '30px', height: '30px', backgroundColor: '#eee'}} /> : <img loading='lazy' title='Otvori pretragu' src={searchIco} onClick={() => setShowSearch(true)} style={{cursor: 'pointer'}} />}
                </NavigationStyled>
            </Container>
        </div>
    )
}
