import React, {useState} from 'react'
import styled from 'styled-components';
import share from '../../../assets/single_news_page/share.svg'
import comment from '../../../assets/single_news_page/comment.svg';
import './ShareComment.css';

import {GrFacebook} from 'react-icons/gr';
import {BsTwitter, BsWhatsapp} from 'react-icons/bs';
import {FaViber} from 'react-icons/fa';
import {GoMailRead} from 'react-icons/go';

import {FacebookShareButton, TwitterShareButton, ViberShareButton, WhatsappShareButton, EmailShareButton} from 'react-share';

const ShareCommentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 22px;
    img{
        &:first-child{
            padding-right: 33px;
        }
    }
    p{
        margin-left: 11px;
    }
`
const CommentNumber = styled.p`
    font-weight: ${props => props.theme.boldWeight};
`

export default function ShareComent({ commentNumber }) {
    const [showShare, setShowShare] = useState(false);
    return (
        <div>
            <ShareCommentWrapper>
                <img loading='lazy' onClick={() => setShowShare(prev => !prev)} style={{cursor: 'pointer'}} src={share} />
                <img loading='lazy' src={comment} />
                <CommentNumber>{commentNumber}</CommentNumber>
            </ShareCommentWrapper>
                {showShare && <div className='shareDiv'>
                    <div>
                        {/* <div id='emailShareBtnDiv'><EmailShareButton url={window?.location?.href}></EmailShareButton><GoMailRead title='Podijeli preko email-a' /></div> */}
                        <div id='facebookShareBtnDiv'><FacebookShareButton url={window?.location?.href}><GrFacebook title='Podijeli na Facebook-u' /></FacebookShareButton></div>
                        <div id='twitterShareBtnDiv'><TwitterShareButton url={window?.location?.href}><BsTwitter title='Podijeli na Twitter-u' /></TwitterShareButton></div>
                        <div id='whatsappShareBtnDiv'><WhatsappShareButton url={window?.location?.href}><BsWhatsapp title='Podijeli na Whatsapp-u' /></WhatsappShareButton></div>
                        <div id='viberShareBtnDiv'><ViberShareButton url={window?.location?.href}><FaViber title='Podijeli na Viber-u' /></ViberShareButton></div>
                    </div>
                </div>}
        </div>
    )
}
