import React from 'react'
import styled from 'styled-components';
import { RowWrapper } from '../../styled/Layout.styled';
import NewsText, { NewsColWrapper } from './NewsText';

export const SingleNewsWrapper = styled.div` //DA LI PRETVORITI U A TAG JAVLJA GREŠKU U KONZOLI
    &:hover{
        cursor: pointer;
    }
`
export const SingleNewsRowWrapper = styled(RowWrapper)`
    padding-bottom:27px;
`

export const TopNewsText = styled(NewsText)`
    ${NewsColWrapper}{
        height: ${props => props.theme.pictureAndFrameHeight};
    }
`
export const NewsImg = styled.img`
    object-fit: cover;
    width: 120px;
    height: ${props => props.theme.pictureAndFrameHeight};
    margin-right: 10px;
`

export default function SingleNews({ img, heading, category, dateTime, className = "single-news" }) {
    return (
        <div className={className}>
            <SingleNewsWrapper>
                <SingleNewsRowWrapper>
                    <NewsImg src={img} />
                    <TopNewsText category={category} dateTime={dateTime} heading={heading} />
                </SingleNewsRowWrapper>
            </SingleNewsWrapper>
        </div>
    )
}


