import { useRef } from "react";

import { API_ENDPOINT } from "../../constants";
import useSeen from "../../hooks/useSeen";

import {useLocation} from 'react-router-dom';

export default function Banner({data, h}) {
    const ref = useRef();
    const isSeen = useSeen(ref);
    const loc = useLocation();

    const createMarkup = (html) => {
        return {__html: html}
    }

    if (isSeen) {
        if (data) {
            const banner = data._id;
            const position = data.positionId;
            fetch(API_ENDPOINT + '/registerBannerVisit', {
                method: 'POST',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({banner, position})
            }).then(res => res.json())
            .then(data => console.log('Successfully registered visit'))
            .catch(err => console.log(err));
        }
    }

    const handleClick = async(data) => {
        const banner = data._id;
        const link = data.link || 'HTML Banner Clicked';
        const location = API_ENDPOINT + loc.pathname;

        fetch(API_ENDPOINT + '/registerBannerClick', {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({banner,link,location})
        }).then(res => res.json())
        .then(data => console.log('Successfully registered click'))
        .catch(err => console.log(err))
    }

    if (data) {
        if (data.type === '1') { // image
            return <div onClick={() => handleClick(data)} ref={ref} style={{width: 'auto', maxWidth: '100%', height: h || 'auto', }}>
                    <a style={{textDecoration: 'none'}} target='_blank' href={data.link} title={data.title}><img loading='lazy' src={API_ENDPOINT + data.image} alt='reklama' style={{objectFit: 'contain', height: '100%', width: '100%'}} /></a>
                </div>
        } else if (data.type === '2') { // adsense
            return <div onClick={() => handleClick(data)} ref={ref} style={{width: '100%', height: h || 'auto', cursor: 'pointer'}}>
                <div dangerouslySetInnerHTML={createMarkup(data.adsense)} />
            </div>
        } else {
            console.log('error - no banner');
            return;
        }
    } 
}