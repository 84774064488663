import React from 'react'
import styled from 'styled-components';

const TagButton = styled.button`
  padding: 8px 17px;
  background-color: ${props => props.theme.whiteColor};
  border: none;
  font-size: 0.625rem;
  margin-right: 10px;
  text-transform:  uppercase;
  margin-bottom: 10px;
`

export default function Tag({tagText}) {
  return (
    <div>
        <TagButton>{tagText}</TagButton>
    </div>
  )
}
