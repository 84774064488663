import React, {useState, useEffect, useRef} from 'react'
import styled from 'styled-components';
import { RowWrapper } from '../components/styled/Layout.styled';
import { Col, Row, Container } from 'reactstrap'
import NewsText, { NewsHeading, NewsDateTime } from '../components/regular/single_news/NewsText'
import ShareComent from '../components/regular/global/ShareComent';
import { FeatureHeadingText } from '../components/styled/FeatureTextHeading.Styled';
import { ShortLine } from '../components/styled/ShortLine.Styled'
import Tag from '../components/regular/global/Tag'
import { RedButton } from '../components/styled/Button.styled';
import Comment from '../components/regular/global/Comment';
import Response from '../components/regular/global/Response';
import SportContentMainRow from '../components/regular/sport_content/SportContentMainRow';
import LatestNewsColumn from '../components/regular/global/LatestNewsColumn'
import WhiteFrameBanner from '../components/regular/global/WhiteFrameBanner';
import PopularNewsColumn from '../components/regular/global/PopularNewsColumn';
import PopularVideosColumn from '../components/regular/global/PopularVideosColumn';

import Banner from '../components/banners/Banner';
import Placeholder from '../components/banners/Placeholder';

import {PaddingRow, SportVerticalNews} from '../components/regular/sport_content/SportContentMainRow'

import {Link, useParams} from 'react-router-dom';

import PhotoModal from '../components/modal/PhotoModal';

import { API_ENDPOINT } from '../constants';

import './SingleNewsPage.css';

import DocumentMeta from 'react-document-meta';


const TagsRowWrapper = styled(RowWrapper)`
    flex-wrap: wrap;
`

// const newsText = {
//     heading: "Danski epidemiolog: Izgleda da su Šveđani stekli imunitet i da je epidemija korone gotova",
//     category: "Bijeljina",
//     dateTime: "21.09.2020.  |  14:30"
// }

// const tags = ["pozar", "vrtic", "vatrogasci", "evakuacija", "djeca", "srbija"]


const SingleNewsText = styled(NewsText)`
    ${NewsHeading}{
        font-size: 2.5rem;
        font-weight: ${props => props.theme.extraBoldWeight};
        margin-bottom: 20px;
        
        overflow: unset;
        text-overflow: unset;
        display: flex;
        -webkit-line-clamp: unset; /* number of lines to show */
        line-clamp: unset; 
        -webkit-box-orient: unset;

        @media (max-width: 768px) {
            font-size: 2rem;
        }
    }
    ${NewsDateTime}{
        font-size: 0.875rem;
    }
`
const NewsImg = styled.img`
    width: 100%;
    height: auto;
`
const MainText = styled.p`
    font-size: 1.125rem;
    font-weight: ${props => props.theme.boldWeight};
    padding-top: 28px !important;
`

export const RegularText = styled.p`
    font-size: 1.125rem;
`
export const NewsTextWrapper = styled.div`
    background-color: ${props => props.theme.whiteColor};
    box-shadow: 0px 6px 12px rgb(0 0 0 / 10%);
    margin-bottom: 40px;
    p{
            padding: 0 20px 30px 30px;
    }
`

const NameInput = styled.input`
    width: 100%;
    height: 50px;
    background-color: ${props => props.theme.whiteColor};
    border: none;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 0.938rem;
    ::placeholder { 
        color: ${props => props.theme.darkColor};
        font-size: 0.938rem;
    }
`
const TextInput = styled.textarea`
    width: 100%;
    height: 180px;
    background-color: ${props => props.theme.whiteColor};
    border: none;
    margin-bottom: 20px;
    padding: 15px 20px;
    font-size: 0.938rem;
    resize: none;
    ::placeholder { 
        color: ${props => props.theme.darkColor};
        font-size: 0.938rem;
    }
`
const SendButton = styled(RedButton)`
    padding:12px 46px;
    margin-bottom: 33px;
    font-size: 1.063rem;
    text-transform: uppercase;
    font-weight: ${props => props.theme.boldWeight};
`
const CommentResponseArea = styled.div`
    margin-bottom: 44px;
`
const comment1 = {
    username: "Mike",
    text: "Na kraju vam uvek ostane da dodjete u SLO, i uzivate u nekim skoro pa netaknutim alpskim selima. A mozete i do A ili I skoknuti usput na izlet.",
    dateTime: "24.10.2020.  |  21:42 h"
}

const response1 = {
    username: "Toby",
    text: "Pre tri godine smo odlucili da vise ne idemo u Sloveniju.",
    dateTime: "24.10.2020.  |  21:42 h"
}

export default function SingleNewsPage() {
    const params = useParams();
    const alias = params.alias;
    const [post, setPost] = useState(null);
    const [category, setCategory] = useState(null);
    const [comments, setComments] = useState([]);
    const [tags, setTags] = useState([]);
    const [relatedPosts, setRelatedPosts] = useState([]);

    const [banner1, setBanner1] = useState(null);
    const [banner2, setBanner2] = useState(null);

    const cNameRef = useRef();
    const cTextRef = useRef();

    const [err, setErr] = useState(null);
    const [msg, setMsg] = useState(null);

    const [upd, setUpd] = useState(1);

    const [modal, setModal] = useState(false);
    const [imgSrc, setImgSrc] = useState(null);

    useEffect( () => {
        setPost(null);
        setCategory(null);
        setComments([]);
        setTags([]);
        setRelatedPosts([]);
        setModal(false);
        setImgSrc(null);
        fetch(API_ENDPOINT + `/getSinglePost/${alias}`, {method: 'GET'})
        .then(res => res.json())
        .then(data => {
            setPost(data.post);
            setCategory(data.category);
            setComments(data.comments);
            setRelatedPosts(data.relatedPosts);
            data.post.keywords && setTags(data.post.keywords);
        });
    }, [upd, alias]);

    useEffect(() => {
        fetch(API_ENDPOINT + '/getBanners/SingleNewsPage', {method: 'GET'})
        .then(res => res.json())
        .then(data => {
            data.forEach(arr => {
                arr.forEach(i => {
                    if (i.position === 'SingleNewsPage-1') {
                        setBanner1(i);
                    } else if (i.position === 'SingleNewsPage-2') {
                        setBanner2(i);
                    }
                })
            })
        })
    }, [])

    useEffect(() => {
        if (window) {
            window.scrollTo(0,0)
        }
    } ,[alias])

    const newsText = {
        heading: post?.title,
        category: category?.categoryName,
        dateTime: `${new Date(post?.date).toLocaleDateString('sr-SR', {day: 'numeric', month: 'numeric', year: 'numeric'})}  |  ${post?.time}`
    }

    const createMarkup = () => {
        return {__html: post?.details}
    }

    const handleSendComment = async() => {
        setErr(null);
        setMsg(null);
        const name = cNameRef.current.value;
        const comment = cTextRef.current.value;

        if (!name || !comment || name === '' || comment === '') {
            setErr('Sva polja su obavezna!');
            return;
        }

        const obj = {name, comment, post:post._id}

        fetch(API_ENDPOINT + '/addNewComment', {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(obj)
        })
        .then(res => res.json())
        .then(data => {
            data?.error && setErr(data.err);
            data?.message && setMsg(data.message);
            setTimeout(() => {
                setErr(null);
                setMsg(null);
                cNameRef.current.value = '';
                cTextRef.current.value = '';
                
            }, 3000)
        })
        .catch(err => {
            setErr('Slanje komentara nije uspjelo!');
        })
    }

    const showModal = (img) => {
        setImgSrc(img);
        setModal(true);
    }

    const hideModal = () => {
        setModal(false);
        setImgSrc(null);
    }

    const meta = {
        title: post?.title,
        description: post?.shortDescription,
        meta: {
            charset: 'utf-8',
            name: {
                'og:title' : post?.title,
                'og:image' : post?.images[0],
                'og:description' : post?.shortDescription,
                'og:url' : window?.location?.href
            }
        }
    }

    return (
        <div>
            <DocumentMeta {...meta} />
            <Container>
                <Row>
                    <Col lg={8}>
                        <SingleNewsText {...newsText} />
                        <ShareComent commentNumber={comments?.length} />
                        <NewsTextWrapper>
                            <NewsImg src={API_ENDPOINT + post?.images[0]} />
                            {post?.photo && <p className='singlePagePhotoCredit'>Kredit: {post?.photo}</p>}
                            <MainText>
                                {post?.shortDescription}
                            </MainText>

                            <RegularText dangerouslySetInnerHTML={createMarkup()}>
                                
                            </RegularText>

                            {post?.images.length > 1 && <RegularText className='photoGaleryP'>
                                <h5>Foto Galerija:</h5>
                                <div>
                                    {post?.images.map((img, i) => {
                                    if (i === 0) {
                                        return;
                                    }
                                    return <div key={img}><img loading='lazy' style={{cursor: 'pointer'}} onClick={() => showModal(API_ENDPOINT+img)} src={API_ENDPOINT+img} alt='slika' /></div>})}
                                </div>
                                
                                </RegularText>}

                                {post?.youtubeId && <RegularText className='youtubeVideoP' style={{textAlign: 'center'}}>
                                <h5>YouTube Video:</h5>
                                <div>
                            <iframe src={post?.youtubeId.includes('/embed/') ? post?.youtubeId : post?.youtubeId.replace('watch?v=', 'embed/')} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </RegularText>}
                        </NewsTextWrapper>
                        {post?.source && <>
                            <FeatureHeadingText red>Izvor</FeatureHeadingText>
                            <ShortLine />
                            <p style={{marginBottom: '20px'}}>{post?.source}</p>
                        </>}
                        {tags.length > 0 && <><FeatureHeadingText red>Tagovi</FeatureHeadingText>
                        <ShortLine />
                        <TagsRowWrapper>
                            {tags.map(tag => <Tag key={tag} tagText={tag} />)}
                        </TagsRowWrapper></>}
                        {!post?.commentsDisable && <><FeatureHeadingText red>Vaš komentar</FeatureHeadingText>
                        <ShortLine />
                        <NameInput required ref={cNameRef} placeholder='Vaše ime' />
                        <TextInput required ref={cTextRef} placeholder='Vaš komentar' />
                        <SendButton style={{cursor: 'pointer'}} onClick={handleSendComment}>Pošalji</SendButton></>}
                        {err && <span style={{marginLeft: '10px', color: 'red'}}>{err}</span>}
                        {msg && <span style={{marginLeft: '10px', color: '#4FAC68'}}>{msg}</span>}
                        {!post?.commentsDisable && <><FeatureHeadingText>Komentari ({comments?.length})</FeatureHeadingText>
                        <ShortLine /></>}

                        {post?.commentsDisable && <p className='commentsDisabledP'>Komentari su onemogućeni za ovu vijest.</p>}
                        
                        {!post?.commentsDisable && comments.length > 0 && comments.map(comment => {
                            const ts = new Date(comment?.tsCreated * 1000);
                            const date = ts.toLocaleDateString('sr-SR');
                            const time = ts.toLocaleTimeString('sr-SR', {hour: '2-digit', minute: '2-digit'});
                            const commentData = {
                                username: comment?.name,
                                text: comment?.comment,
                                dateTime: `${date}  |  ${time}`
                            }
                            let replies = []
                            if (comment.replies.length > 0) {
                                comment.replies.map(reply => {
                                    const ts = new Date(reply?.tsCreated * 1000);
                                    const date = ts.toLocaleDateString('sr-SR');
                                    const time = ts.toLocaleTimeString('sr-SR', {hour: '2-digit', minute: '2-digit'});
                                    const responseData = {
                                        username: reply?.name,
                                        text: reply?.comment,
                                        dateTime: `${date}  |  ${time}`
                                    }
                                    replies.push(responseData);
                                })
                            }
                            return <CommentResponseArea key={comment._id}>
                                <Comment commentData={commentData} commentId={comment._id} setUpd={setUpd} />
                                {replies.length > 0 && replies.map(reply => <Response key={reply.tsCreated} responseData={reply} />)}
                            </CommentResponseArea>
                        })}
                    </Col>
                    <Col lg={4}>
                        <LatestNewsColumn />
                        {/* <WhiteFrameBanner bannerImg={banner1} /> */}
                        {banner1 ? <Banner data={banner1} /> : <Placeholder h='300px' />}
                        <PopularNewsColumn />
                        {/* <WhiteFrameBanner bannerImg={banner1} /> */}
                        {banner2 ? <Banner data={banner2} /> : <Placeholder h='300px' />}
                        <PopularVideosColumn />
                    </Col>
                    {/* <SportContentMainRow /> */}
                    <div>
                        <PaddingRow>
                            {relatedPosts.length > 0 && relatedPosts.map(post => {
                                const ts = new Date(post?.datetime * 1000);
                                const date = ts.toLocaleDateString('sr-SR');
                                const time = ts.toLocaleTimeString('sr-SR', {hour: '2-digit', minute: '2-digit'});
                                const DATA = {
                                    heading: post?.title,
                                    category: category.categoryName,
                                    dateTime: `${date}  |  ${time}`
                                }
                                return <Col key={post._id} md={4} lg={3}>
                                    <Link style={{textDecoration: 'none'}} to={`/vijesti/${post?.alias}`} >
                                        <SportVerticalNews className='smallVerticalNews' img={API_ENDPOINT + post?.images[0]} newsText={DATA} />
                                    </Link>
                                </Col>
                            })}
                        </PaddingRow>
                    </div>
                    
                </Row>
            </Container>
            {modal && <PhotoModal src={imgSrc} close={hideModal} />}
        </div>
    )
}
