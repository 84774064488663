import React, {useState, useEffect} from 'react'
import styled from 'styled-components';
import {RowWrapper } from '../../styled/Layout.styled';
import {Col, Row} from 'reactstrap'
import newsImg from '../../../assets/magazine_latest/kanal-2.png'
import SingleBackgroundNews from '../single_news/SingleBackgroundNews';
import VerticalNews, {VerticalNewsWrapper, VerticalText} from '../single_news/VerticalNews';
import TopNews from '../top_page/TopNews';
import SectionHeading from '../global/SectionHeading';
import { NewsColWrapper } from '../../regular/single_news/NewsText';

import football from '../../../assets/magazine_latest/football.png'
import { API_ENDPOINT } from '../../../constants';
import { Link } from 'react-router-dom';

import './MagazineLatest.css';

// const news2 = {
//     heading: "Rep. Ayanna Pressley Is Challenging Beauty Norms To Make Women Of Color Feel",
//     category: "Republika Srpska",
//     dateTime: "30.10.2020.  | 22:48"
// }

// const news1 = {
//     heading: "Olivia Wilde Is Living Her Best Life",
//     category: "Republika Srpska",
//     dateTime: "30.10.2020.  | 22:48"
// }

const MagazinVerticalNews = styled(VerticalNews)`
    ${VerticalNewsWrapper}{
        height: 510px;
        img {
            object-fit: cover;
            height: 350px;
        }
    }
    ${VerticalText}{
        padding: 10px 0;

    }
`

const RowWrapperSpaceBetween = styled(RowWrapper)`
    justify-content: space-between;
    padding: 0;
    margin: 30px 5px 20px 5px;
    border-bottom: 2px solid ${props => props.theme.darkBorder};
    height: 42px;
`
const SmallLabel = styled.p`
    color: ${props => props.theme.greyColor};
    font-size: 0.875rem;
    
    &:before{
        content:"";
        border: solid ${props => props.theme.redColor};;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        margin-right: 7px;
    }
`

export default function MagazineLatest({section, categoryName, categoryAlias, posts }) {
    const [restPosts, setRestPosts] = useState([]);

    useEffect(() => {
        setRestPosts([]);
        if (posts) {
            posts.map((post, i) => {
                if (i > 4 && i < 9) {
                    setRestPosts(prev => [...prev, post]);
                }
            })
        }
    }, [posts]);

    return (
        <div>

            <SectionHeading categoryName={categoryName} categoryAlias={categoryAlias} redHeading={section} blackHeading="Najnovije" />

            <Row>
                <Col md ={6} lg={6} xl={4}>
                    {posts && posts.length > 0 && posts.map((post, i) => {
                        if (i > 0 && i < 3) {
                            const ts = new Date(post?.datetime * 1000);
                            const date = ts.toLocaleDateString('sr-SR');
                            const time = ts.toLocaleTimeString('sr-SR', {hour: '2-digit', minute: '2-digit'});
                            const DATA = {
                                heading: post?.title,
                                category: post?.category,
                                dateTime: `${date}  |  ${time}`
                            }
                            return <React.Fragment key={post._id}><Link style={{textDecoration: 'none'}} to={`/vijesti/${post?.alias}`}>
                                <SingleBackgroundNews h={240} src={API_ENDPOINT + post?.images[0]} newsText={DATA} />
                            </Link>
                            <div style={{height: '30px'}}></div></React.Fragment>
                        }
                    })}
                    {/* <SingleBackgroundNews src={newsImg} newsText={news1} />
                    <SingleBackgroundNews src={newsImg} newsText={news1} /> */}
                </Col>
                <Col  md ={6} lg={6} xl={4}>
                    <Link style={{textDecoration: 'none'}} to={`/vijesti/${posts[0]?.alias}`}><MagazinVerticalNews className='bigVerticalNews' img={API_ENDPOINT + posts[0]?.images[0]} newsText={{heading: posts[0]?.title, category: posts[0]?.category, dateTime: `${new Date(posts[0]?.datetime * 1000).toLocaleDateString('sr-SR')}  |  ${new Date(posts[0]?.datetime*1000).toLocaleTimeString('sr-SR', {hour: '2-digit', minute: '2-digit'})}`}} /></Link>
                </Col>
                <Col lg={12} xl={4}>
                {posts && posts.length > 0 && posts.map((post, i) => {
                        if (i > 2 && i < 5) {
                            const ts = new Date(post?.datetime * 1000);
                            const date = ts.toLocaleDateString('sr-SR');
                            const time = ts.toLocaleTimeString('sr-SR', {hour: '2-digit', minute: '2-digit'});
                            const DATA = {
                                heading: post?.title,
                                category: post?.category,
                                dateTime: `${date}  |  ${time}`
                            }
                            return <div key={post._id} id='homePageMarginDiv'><Link  style={{textDecoration: 'none'}} to={`/vijesti/${post?.alias}`}>
                                <SingleBackgroundNews h={240} src={API_ENDPOINT + post?.images[0]} newsText={DATA} />
                            </Link><div id='homePageMarginInnerDiv'></div></div>
                        }
                    })}
                    {/* <SingleBackgroundNews src={newsImg} newsText={news1} />
                    <SingleBackgroundNews src={newsImg} newsText={news1} /> */}
                </Col>
            </Row>

            <Row>
                <TopNews categoryName={categoryName} posts={restPosts} />
            </Row>
        </div>
    )
}
