import {useState, useEffect} from 'react'

export default function useSeen(ref) {
    const [isIntersectiong, setIsIntersecting] = useState(false);
    
    const observer = new IntersectionObserver(
        ([entry]) => setIsIntersecting(entry.isIntersecting)
    )

    useEffect(() => {
        observer.observe(ref.current);

        return () => {observer.disconnect()}
    }, []);

    return isIntersectiong;
}