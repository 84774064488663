import React from 'react'
import styled from 'styled-components';
import SectionHeading from '../global/SectionHeading';
import {Row, Col} from 'reactstrap'
import MainSingleNews from '../single_news/MainSingleNews';
import { NewsHeading, NewsColWrapper } from '../single_news/NewsText'
import VerticalNews, { VerticalNewsWrapper, VerticalText } from '../single_news/VerticalNews';
import BannerStyle from '../global/BannerStyle';

import mainImg from '../../../assets/exclusive_content/kanal-big.png'
import football from '../../../assets/exclusive_content/football.png'
import banner from '../../../assets/exclusive_content/banner.png'
import { API_ENDPOINT } from '../../../constants';

import { Link } from 'react-router-dom';

const news1 = {
    heading: "Elliott Puckette Redraws Art Boundaries with New Work at Kasmin Gallery",
    category: "Republika Srpska",
    dateTime: "30.10.2020.  | 22:48"
}

const news2 = {
    heading: "Fanny’s Is Bringing Dinner an a Show to Academy Museum of Motion Pictures",
    category: "Republika Srpska",
    dateTime: "30.10.2020.  | 22:48"
}

const MiniVerticalNews = styled(VerticalNews)`
    ${VerticalText}{
        ${NewsColWrapper}{
            padding-left: 0px;
        }
        ${NewsHeading}{
            font-size: 16px; 
            //padding:0;
        }
    }

    ${VerticalNewsWrapper}{
        min-width: unset;
        background: unset;
        box-shadow: unset;
    }
`

export default function ExclusiveContent({categoryName, categoryAlias, section, posts}) {
    return (
        <div>
            <SectionHeading categoryName={categoryName} categoryAlias={categoryAlias} redHeading={section} blackHeading="Najnovije" />
            <Row>
                {posts[0] && <Col lg={6}>
                    <Link style={{textDecoration: 'none'}} to={`/vijesti/${posts[0]?.alias}`}><MainSingleNews h={560} newsText={{heading: posts[0]?.title, category: posts[0]?.category, dateTime: `${new Date(posts[0]?.datetime * 1000).toLocaleDateString('sr-SR')}  |  ${new Date(posts[0]?.datetime*1000).toLocaleTimeString('sr-SR', {hour: '2-digit', minute: '2-digit'})}`}} newsImg={API_ENDPOINT + posts[0]?.images[0]} /></Link>
                </Col>}
                <Col md={6} lg={3}>
                {posts && posts.length > 0 && posts.map((post, i) => {
                  if (i > 0 && i < 3) {
                        const ts = new Date(post?.datetime * 1000);
                        const date = ts.toLocaleDateString('sr-SR');
                        const time = ts.toLocaleTimeString('sr-SR', {hour: '2-digit', minute: '2-digit'});
                        const DATA = {
                            heading: post?.title,
                            category: post?.category,
                            dateTime: `${date}  |  ${time}`
                        }
                        return <React.Fragment key={post._id}><Link to={`/vijesti/${post?.alias}`} style={{textDecoration: 'none'}}>
                          <MiniVerticalNews className='mediumVerticalNews' newsText={DATA} img={API_ENDPOINT+post?.images[0]} />
                        </Link><div style={{height: '20px'}}></div></React.Fragment>
                  }
              })}
                    {/* <MiniVerticalNews newsText={news2} img={football} />
                    <MiniVerticalNews newsText={news2} img={football} /> */}
                </Col>
                <Col md={6} lg={3}>
                {posts && posts.length > 0 && posts.map((post, i) => {
                  if (i > 2 && i < 5) {
                        const ts = new Date(post?.datetime * 1000);
                        const date = ts.toLocaleDateString('sr-SR');
                        const time = ts.toLocaleTimeString('sr-SR', {hour: '2-digit', minute: '2-digit'});
                        const DATA = {
                            heading: post?.title,
                            category: post?.category,
                            dateTime: `${date}  |  ${time}`
                        }
                        return <React.Fragment key={post._id}><Link to={`/vijesti/${post?.alias}`} style={{textDecoration: 'none'}}>
                          <MiniVerticalNews className='mediumVerticalNews' newsText={DATA} img={API_ENDPOINT+post?.images[0]} />
                        </Link><div style={{height: '20px'}}></div></React.Fragment>
                  }
              })}
                    {/* <MiniVerticalNews newsText={news2} img={football} />
                    <MiniVerticalNews newsText={news2} img={football} /> */}
                </Col>
            </Row>
            {/* <BannerStyle src={banner}/> */}

        </div>
    )
}
