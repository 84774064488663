import React, {useEffect, useState} from 'react'
import styled from 'styled-components';
import { Col, Row, Container } from 'reactstrap'
import { RowWrapper } from '../../styled/Layout.styled';
import MainHeading, {MainHeadingWord} from '../global/MainHeading';

import { Link } from 'react-router-dom';
//import images
import clockIco from '../../../assets/top_page/clock.svg'
import cloudIco from '../../../assets/top_page/cloud.svg'
import facebookIco from '../../../assets/top_page/facebook.svg'
import instaIco from '../../../assets/top_page/instagram.svg'
import youtubeIco from '../../../assets/top_page/youtube.svg'

import logo from '../../../assets/logo/hit-logo.svg';

const RowWrapperCenterJustify = styled(RowWrapper)`
    @media (max-width: 920px) {
       justify-content:center;
    }
`

const HeaderMainHeading = styled(MainHeading)`
${MainHeadingWord}{
    @media (max-width: 994px) {
       font-size: 2.25rem !important;
    }
}
    
` 

export const HeaderTextElement = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right:auto;
    p, a{
        font-size: 0.875rem;
        color: ${props => props.theme.darkColor};
    }

    a:hover{
        cursor: pointer;
    }

    img{
        margin-right: 0.625rem;
    }

    @media (max-width: 992px) {
        margin-right:9px;
    }
`

const HeaderDisappearElement = styled(HeaderTextElement)`
    @media (max-width: 768px) {
        display: none;
    }
`

export const MediaIcons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    a{
        &:not(:last-child){
            margin-right: 20px;
        }
    }

    @media (max-width: 920px) {
        a{
            &:not(:last-child){
                margin-right: 7px;
            }
        }
    }
`
export const LineBreak = styled.div`
    width: 100%;
    border-bottom: 2px solid ${props => props.theme.lightGreyColor};
`

const HeaderCenterRow = styled(Row)`
    align-items: center;
`
// .toLocaleDateString('sr-SR', {month: 'long', day:'numeric', year: 'numeric'});

const months = [
    'x',
    'Januar',
    'Februar',
    'Mart',
    'April',
    'Maj',
    'Jun',
    'Jul',
    'Septembar',
    'Oktobar',
    'Novembar',
    'Decembar'
]

export default function Header() {
    const now = new Date(Date.now());
    const year = now.getFullYear();
    const day = now.getDate();
    const monthNum = now.getMonth();
    
    const [temp, setTemp] = useState(null);
    const [weatherIcon, setWeatherIcon] = useState(null);

    useEffect(() => {
        fetch('https://api.openweathermap.org/data/2.5/weather?id=3204186&appid=ead872841a3f922859ce83fabb5b5e39')
            .then(res => res.json()).then((result) => {
                // console.log(result);
                let icon_path = `https://openweathermap.org/img/w/` + result.weather[0].icon + `.png`;
                setTemp(Math.round(result.main.temp - 273));
                setWeatherIcon(icon_path);
            })
            .catch(function (err) {
                console.log(err.message || err || 'Cound not fetch weather data')
            });
    }, [])
    return (
        
            <Container>
                <HeaderCenterRow>

                    <Col xs={4} md={4}>
                        <RowWrapperCenterJustify>

                            <HeaderTextElement>
                                <img loading='lazy' src={weatherIcon} />
                                <p>{temp}°C</p>
                            </HeaderTextElement>

                            <HeaderDisappearElement>
                                <img loading='lazy' src={clockIco} />
                                <p>{`${months[monthNum]} ${day}, ${year}`}</p>
                            </HeaderDisappearElement>

                        </RowWrapperCenterJustify>
                    </Col>

                    <Col xs={4} md={4}>
                        <RowWrapperCenterJustify justify="center" >
                            {/* <HeaderMainHeading redText="Trendy" blackText="News" /> */}
                            <Link to='/'><img loading='lazy' style={{marginBottom: '10px'}} alt='logo' src={logo} /></Link>
                        </RowWrapperCenterJustify>
                    </Col>

                    <Col xs={4} md={4}>
                        <RowWrapperCenterJustify justify="right">
                            <HeaderDisappearElement>
                                <Link style={{textDecoration: 'none'}} to='/stranica/marketing'>Marketing</Link>
                            </HeaderDisappearElement>
                            <HeaderDisappearElement>
                                <Link style={{textDecoration: 'none'}} to='/stranica/kontakt'>Kontakt</Link>
                            </HeaderDisappearElement>
                            <MediaIcons>
                            <a style={{textDecoration: 'none'}} title='Facebook' href='https://www.facebook.com/radiotelevizijahit/' target='_blank' ><img loading='lazy' src={facebookIco} /></a>
                            <a style={{textDecoration: 'none'}} title='Instagram' href='' target='_blank' ><img loading='lazy' src={instaIco} /></a>
                            <a style={{textDecoration: 'none'}} title='Youtube' href='https://www.youtube.com/c/RTVHITOfficial' target='_blank' ><img loading='lazy' src={youtubeIco} /></a>
                                {/* <img loading='lazy' src={facebookIco} />
                                <img loading='lazy' src={instaIco} />
                                <img loading='lazy' src={youtubeIco} /> */}
                            </MediaIcons>
                        </RowWrapperCenterJustify>
                    </Col>

                </HeaderCenterRow>

                <LineBreak />

            </Container>


    )
}
