import styled from 'styled-components';
import {Col, Row, Container} from 'reactstrap'
import SingleNews, {SingleNewsWrapper, NewsImg, TopNewsText, SingleNewsRowWrapper} from '../single_news/SingleNews'
import { BleedWrapper, RowWrapper } from '../../styled/Layout.styled';
import { NewsHeading, NewsDateTime, NewsColWrapper } from '../single_news/NewsText';

import objektiv from '../../../assets/objektiv.png';
import triMinuta from '../../../assets/tri-minuta.png';
import otvoreniStudio from '../../../assets/otvoreni-studio.png';
import trecePoluvrijeme from '../../../assets/trece-poluvrijeme.png';
import { Link } from 'react-router-dom';

export const TopNewsWrapper = styled.div`
    margin-top: 21px;
`

const TopSingleNews = styled(SingleNews)`
    @media (max-width: 595px) {
        ${SingleNewsRowWrapper}{
        padding: 0;
        }
        ${NewsColWrapper}{
            justify-content:center;
        }
        ${NewsImg}{
            width: 70px;
            height: 50px;
        }
        ${TopNewsText}{
            ${NewsHeading}{
                font-size:13px;
            }
        
            ${RowWrapper}{
                display:none;
            }
        }
    }
`

export default function Featured({ className = "top-news" }) {


    return (
        <div className={className}>
            
            <TopNewsWrapper>
                <BleedWrapper style={{marginBottom: '20px'}}>
                    <Col xl={3} md={6} sm={6} xs={6}>
                        <Link style={{textDecoration: 'none'}} to={`/stranica/objektiv`}>
                            <img loading='lazy' style={{objectFit: 'cover', width: '100%', height: '100%'}} alt='objektiv' src={objektiv} />
                        </Link>
                    </Col>
                    <Col xl={3} md={6} sm={6} xs={6}>
                        <Link style={{textDecoration: 'none'}} to={`/stranica/tri-minuta`}>
                            <img loading='lazy' style={{objectFit: 'cover', width: '100%', height: '100%'}} alt='tri-minuta' src={triMinuta} />
                        </Link>
                    </Col>
                    <Col xl={3} md={6} sm={6} xs={6}>
                        <Link style={{textDecoration: 'none'}} to={`/stranica/otvoreni-studio`}>
                            <img loading='lazy' style={{objectFit: 'cover', width: '100%', height: '100%'}} alt='otvoreni-studio' src={otvoreniStudio} />
                        </Link>
                    </Col>
                    <Col xl={3} md={6} sm={6} xs={6}>
                        <Link style={{textDecoration: 'none'}} to={`/stranica/trece-poluvrijeme`}>
                            <img loading='lazy' style={{objectFit: 'cover', width: '100%', height: '100%'}} alt='trece-poluvrijeme' src={trecePoluvrijeme} />
                        </Link>
                    </Col> 
                </BleedWrapper>
            </TopNewsWrapper>
        </div>
    )
}
