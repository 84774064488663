import React, {useState, useEffect} from 'react';
import MainHeading from './MainHeading';
import {Col, Row, Container} from 'reactstrap'
import VerticalShortVideoNews from '../single_news/VerticalShortVideoNews';

import LoadingSpinner from '../../spinner/LoadingSpinner';

//import imgs
import basketball from '../../../assets/magazine_page/basketball.png'
import kanalSmall from '../../../assets/magazine_page/kanal_small.png'
import { API_ENDPOINT } from '../../../constants';
import { Link } from 'react-router-dom';

// const singleNews1 = {
//     heading: "Buying A Dress? Why Not A Farm, While You're At It?"
// }

export default function PopularVideosColumn() {
    const [popularVideoPosts, setPopularVideoPosts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setPopularVideoPosts([]);
        setLoading(true);
        fetch(API_ENDPOINT + '/getPopularVideoNews', {method: 'GET'})
        .then(res => res.json())
        .then(data => {setPopularVideoPosts(data); setLoading(false)})
        .catch(err => console.log(err))
    }, [])

    // console.log(popularVideoPosts);
    return (
        <div>

            <MainHeading redText="Video" />
            {loading ? <LoadingSpinner /> : <div>
            <Row>
                <Col>
                {popularVideoPosts.length > 0 && popularVideoPosts.map((post, i) => {
                    if (i < 2) {
                        const text = {heading: post.title};
                        const img = API_ENDPOINT + post?.images[0];
                        return <Link key={post._id} style={{textDecoration: 'none'}} to={`/vijesti/${post?.alias}`}><VerticalShortVideoNews newsText={text} img={img} /></Link>
                    }
                })}
                    {/* <VerticalShortVideoNews newsText={singleNews1} img={basketball} />
                    <VerticalShortVideoNews newsText={singleNews1} img={kanalSmall} /> */}
                </Col>
                <Col>
                {popularVideoPosts.length > 0 && popularVideoPosts.map((post, i) => {
                    if (i > 1) {
                        const text = {heading: post.title};
                        const img = API_ENDPOINT + post?.images[0];
                        return <Link key={post._id} style={{textDecoration: 'none'}} to={`/vijesti/${post?.alias}`}><VerticalShortVideoNews newsText={text} img={img} /></Link>
                    }
                })}
                    {/* <VerticalShortVideoNews newsText={singleNews1} img={kanalSmall} />
                    <VerticalShortVideoNews newsText={singleNews1} img={basketball} /> */}
                </Col>
            </Row>
            </div>}
        </div>
    )
}
