import React, {useState, useEffect} from 'react';
import MainHeading from './MainHeading';
import SingleNews from '../single_news/SingleNews';

import LoadingSpinner from '../../spinner/LoadingSpinner';

import { Link } from 'react-router-dom';

//improt img
import smallCake from '../../../assets/magazine_page/cake_small.png'
import basketball from '../../../assets/magazine_page/basketball.png'
import { API_ENDPOINT } from '../../../constants';
import HorizontalNews from '../single_news/HorizontalNews';

const singleNews1 = {
    heading: "The Launch: February's Hottest Fashion Drops",
    category: "Bijeljina",
    dateTime: "30.10.2020.  | 22:48"
}

const singleNews2 = {
    heading: "Buying A Dress? Why Not A Farm, While You're At It?",
    category: "Bijeljina",
    dateTime: "30.10.2020.  | 22:48"
}

export default function LatestNewsColumn() {
    const [latestPosts, setLatestPosts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLatestPosts([]);
        setLoading(true);
        fetch(API_ENDPOINT + '/getMiniLatestNews', {method: 'GET'})
        .then(res => res.json())
        .then(data => {setLatestPosts(data); setLoading(false)})
        .catch(err => console.log(err))
    }, []);

    return (
        <div>
            <MainHeading redText="Najnovije" />
            {loading ? <LoadingSpinner /> : <div>
            {latestPosts.length > 0 && latestPosts.map(post => {
            const ts = new Date(post?.datetime * 1000);
            const date = ts.toLocaleDateString('sr-SR');
            const time = ts.toLocaleTimeString('sr-SR', {hour: '2-digit', minute: '2-digit'});
            const DATA = {
                heading: post?.title,
                category: post?.cat[0]?.categoryName,
                dateTime: `${date}  |  ${time}`
            }
            return <Link key={post?._id} style={{textDecoration: 'none'}} to={`/vijesti/${post?.alias}`}>
                <HorizontalNews className='latestNewsContainer' {...DATA} img={API_ENDPOINT+post?.images[0]} />
                {/* <SingleNews {...DATA} img={API_ENDPOINT+post?.images[0]} /> */}
            </Link>})}
            </div>}
        </div>
    )
}
