import React from 'react'
import styled from 'styled-components';
import NewsText, { NewsColWrapper, NewsHeading, NewsDateTime } from './NewsText';

import './SingleBackgroundNews.css';


export const SingleBackgroundNewsWrapper = styled.div`
    position: relative;
    overflow: hidden;
    height: ${props => props.h}px;
`

export const NewsTextBackgroundNews = styled(NewsText)`
    ${NewsColWrapper}{
        position:absolute;
        bottom: 1px;
        left: 0px;
        padding: 0 50px 20px 22px;
        width:100%;
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9));
    }
    ${NewsHeading}{
        color:${props => props.theme.whiteColor};
        margin-bottom: 15px;
        font-size: 1.375rem;
    }  
    ${NewsDateTime}{
        color:${props => props.theme.whiteColor};
    }
`

export const Img = styled.img`

`

export default function SingleBackgroundNews({h, children, className = "single-background-news-wrapper", newsText, src }) {
    return (
        <div className={className}>
            <SingleBackgroundNewsWrapper h={h}>
                <NewsTextBackgroundNews {...newsText} />
                <img loading='lazy' className='miniPopularNewsBgImage' src={src} />
                {children}
            </SingleBackgroundNewsWrapper>
        </div>
    )
}
