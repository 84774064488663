import React from 'react'
import styled from 'styled-components'
import SingleBackgroundNews, { NewsTextBackgroundNews, Img } from '../single_news/SingleBackgroundNews'
import { Col, Row, Container } from 'reactstrap'
import  { NewsHeading } from '../single_news/NewsText';

//import img
import videoImg from '../../../assets/video_content/video.svg'
import kanalBig from '../../../assets/video_content/kanal-big.png'
import kanalSmall from '../../../assets/video_content/kanal-small.png'
import play from '../../../assets/video_content/play.svg'
import { Link } from 'react-router-dom';
import { API_ENDPOINT } from '../../../constants';


const VideoImg = styled.img`
  margin-top: auto;
  position: absolute;
  top: 160px;
  left:24px;
  width: 100%;
  height: auto;

  @media (max-width: 1200px) {
    display:none;
  }
`

const news1 = {
  heading: "Farrow and Ball’s Collaboration with Liberty of London Revives the Archive",
  category: "Republika Srpska",
  dateTime: "30.10.2020.  | 22:48"
}

const news2 = {
  heading: "Olivia Wilde Is Living Her Best Life",
  category: "Republika Srpska",
  dateTime: "30.10.2020.  | 22:48"
}
const VideoIcon = styled.img`
  &:hover{
    cursor: pointer;
  }
`
const SingleBackgroundNewsBigVideo = styled(SingleBackgroundNews)`
  ${Img}{
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  ${VideoIcon}{
    position: absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
  }

  ${NewsTextBackgroundNews}{
    ${NewsHeading}{
      font-size: 30px;
    }
  }
`

const SingleBackgroundNewsSmallVideo = styled(SingleBackgroundNews)`
  margin-bottom: 11px;
  img{
    object-fit: cover;
    width: 100%;

    height: auto;
    max-height: 189px;
  }

  ${VideoIcon}{
    width: 24px;
    height: 24px;
    position: absolute;
    top:10%;
    right:10%;
  }
`

const VideoContentWrapper = styled.div`
  background-color: ${props => props.theme.whiteColor};
  padding: 45px 0;
`
const VideoHeadingWrapper = styled.div`
  position: relative;
  bottom: 0px;
`

export default function  VideoContent({posts, categoryName, categoryAlias}) {

  return (
    <div>
      {posts && <VideoContentWrapper>
        <Container>
          <Row>
            <Col xl={1}>
              <VideoHeadingWrapper>
                <VideoImg src={videoImg} />
              </VideoHeadingWrapper>
            </Col>
            {/* const news1 = {
  heading: "Farrow and Ball’s Collaboration with Liberty of London Revives the Archive",
  category: "Republika Srpska",
  dateTime: "30.10.2020.  | 22:48"
} */}
            <Col sm={12} lg={6} xl={5} style={{marginBottom: '10px'}}>
              <Link style={{textDecoration: 'none'}} to={`/vijesti/${posts[0]?.alias}`}><SingleBackgroundNewsBigVideo h={420} newsText={{heading: posts[0]?.title, category: posts[0]?.category, dateTime: `${new Date(posts[0]?.datetime * 1000).toLocaleDateString('sr-SR')}  |  ${new Date(posts[0]?.datetime*1000).toLocaleTimeString('sr-SR', {hour: '2-digit', minute: '2-digit'})}`}} src={API_ENDPOINT + posts[0]?.images[0]} >
                <VideoIcon src={play} />
              </SingleBackgroundNewsBigVideo></Link>
            </Col>
            <Col sm={6} lg={3}>
              {posts && posts.length > 0 && posts.map((post, i) => {
                  if (i > 0 && i < 3) {
                        const ts = new Date(post?.datetime * 1000);
                        const date = ts.toLocaleDateString('sr-SR');
                        const time = ts.toLocaleTimeString('sr-SR', {hour: '2-digit', minute: '2-digit'});
                        const DATA = {
                            heading: post?.title,
                            category: post?.category,
                            dateTime: `${date}  |  ${time}`
                        }
                        return <React.Fragment key={post._id}><Link to={`/vijesti/${post?.alias}`} style={{textDecoration: 'none'}}>
                          <SingleBackgroundNewsSmallVideo h={200} newsText={DATA} src={API_ENDPOINT+post?.images[0]} >
                            <VideoIcon src={play} />
                          </SingleBackgroundNewsSmallVideo>
                        </Link><div style={{height: '10px'}}></div></React.Fragment>
                  }
              })}
              {/* <SingleBackgroundNewsSmallVideo newsText={news1} src={kanalSmall} >
                <VideoIcon src={play} />
              </SingleBackgroundNewsSmallVideo>
              <SingleBackgroundNewsSmallVideo newsText={news1} src={kanalSmall} >
                <VideoIcon src={play} />
              </SingleBackgroundNewsSmallVideo> */}
            </Col>
            <Col sm={6} lg={3}>
              {posts && posts.length > 0 && posts.map((post, i) => {
                  if (i > 2) {
                        const ts = new Date(post?.datetime * 1000);
                        const date = ts.toLocaleDateString('sr-SR');
                        const time = ts.toLocaleTimeString('sr-SR', {hour: '2-digit', minute: '2-digit'});
                        const DATA = {
                            heading: post?.title,
                            category: post?.category,
                            dateTime: `${date}  |  ${time}`
                        }
                        return <React.Fragment key={post._id}><Link key={post._id} to={`/vijesti/${post?.alias}`} style={{textDecoration: 'none'}}>
                          <SingleBackgroundNewsSmallVideo h={200} newsText={DATA} src={API_ENDPOINT+post?.images[0]} >
                            <VideoIcon src={play} />
                          </SingleBackgroundNewsSmallVideo>
                        </Link><div style={{height: '10px'}}></div></React.Fragment>
                  }
              })}
              {/* <SingleBackgroundNewsSmallVideo newsText={news1} src={kanalSmall} >
                <VideoIcon src={play} />
              </SingleBackgroundNewsSmallVideo>
              <SingleBackgroundNewsSmallVideo newsText={news1} src={kanalSmall} >
                <VideoIcon src={play} />
              </SingleBackgroundNewsSmallVideo> */}
            </Col>
          </Row>
        </Container>
      </VideoContentWrapper>}
    </div>
  )
}


