import React, {useState, useEffect} from 'react'
import styled from 'styled-components';
import SectionHeading from '../global/SectionHeading';

import TopNews, { TopNewsWrapper } from '../top_page/TopNews';
import SportContentMainRow from './SportContentMainRow';


// const news1 = {
//     heading: "Shy Stuart the Dobermann is ‘new dog’ after starting Canicross",
//     category: "Republika Srpska",
//     dateTime: "30.10.2020.  | 22:48"
// }

const TopNewsSport = styled(TopNews)`
    ${TopNewsWrapper}{
        margin-top: 0; 
    }
`

export default function SportContent({section, categoryName, categoryAlias, posts}) {
    const [mainPosts, setMainPosts] = useState([])
    const [restPosts1, setRestPosts1] = useState([]);
    const [restPosts2, setRestPosts2] = useState([]);

    useEffect(() => {
        setMainPosts([]);
        setRestPosts1([]);
        setRestPosts2([]);
        if (posts) {
            posts.map((post, i) => {
                if (i < 4) {
                    setMainPosts(prev => [...prev, post]);
                }
                else if (i > 3 && i < 8) {
                    setRestPosts1(prev => [...prev, post]);
                } else if (i > 7) {
                    setRestPosts2(prev => [...prev, post]);
                }
            })
        }
    }, [posts]);

    useEffect(() => {
        if (posts) {

        }
    }, [posts])

    return (
        <div>
            <SectionHeading categoryName={categoryName} categoryAlias={categoryAlias} redHeading={section} blackHeading="Najnovije" />
            <SportContentMainRow posts={mainPosts} />
            <TopNewsSport posts={restPosts1} />
            <TopNewsSport posts={restPosts2} />
        </div>
    )
}
