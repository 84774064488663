import React from 'react'
import Header from './Header'
import Navigation from './Navigation'
import styled from 'styled-components'

export const TopPageWrapper = styled.div`
    background-color: ${props => props.theme.whiteColor} ;
    box-shadow: 0px 2px 11px -6px rgb(0 0 0 / 75%);
`

export default function Top() {
    return (
        <TopPageWrapper>
            <Header />
            <Navigation />
        </TopPageWrapper>
    )
}
