import React, {useState, useEffect} from 'react'
import styled from 'styled-components';
import { RowWrapper } from '../../styled/Layout.styled';
import NewsText, { NewsHeading, NewsDateTime, NewsColWrapper } from '../single_news/NewsText';
import MainHeading, {MainHeadingWord} from '../global/MainHeading'
import { API_ENDPOINT } from '../../../constants';
import { Link } from 'react-router-dom';

const news1 = {
    heading: "The Nordic look that defined freedom and joy",
    category: "Culture",
    dateTime: "30.10.2020.  | 22:48"
}

const MainHeadingPopularNews = styled(MainHeading)`
    @media (max-width: 992px) {
        display: none;
    }
`

// const news2 = {
//     heading: "The French icon who revolutionized women's clothes",
//     category: "Culture",
//     dateTime: "30.10.2020.  | 22:48"
// }

export const RightColumnWrapper = styled.div`
    background-color: ${props => props.theme.whiteColor};
    padding: 0 20px;
    box-shadow: 0px 6px 12px rgb(0 0 0 / 10%);
`

export const OnlyTextNews = styled(NewsText)`
    ${NewsHeading}{
        padding-bottom: 4px;
    }

    ${RowWrapper}{
        padding-top: 4px;
    }

    ${NewsColWrapper}{
        padding: 16px 0;
        border-bottom: 2px solid ${props => props.theme.lightGreyColor};
    }
`
const ColumnMainHeading = styled(MainHeading)`
    ${MainHeadingWord}{
        font-size: 50px;
    }
`

export default function RightColumn({posts}) {
    // const [posts, setPosts] = useState([]);
    // useEffect(() => {
    //     setPosts([]);
    //     fetch(API_ENDPOINT + '/getPopularNews', {method: 'GET'})
    //     .then(res => res.json())
    //     .then(data => setPosts(data))
    //     .catch(err => console.log(err))
    // }, [])
    return (
        <div>
            <RowWrapper style={{ height: "60px", paddingTop: "15px" }}>
                {/* <ColumnMainHeading redText="Popular" blackText="News" /> */}
                <ColumnMainHeading redText="Popularno" />
            </RowWrapper>
            <RightColumnWrapper>
                {posts && posts.length > 0 && posts.map(post => {
                    const ts = new Date(post?.datetime * 1000);
                    const date = ts.toLocaleDateString('sr-SR');
                    const time = ts.toLocaleTimeString('sr-SR', {hour: '2-digit', minute: '2-digit'});
                    const DATA = {
                        heading: post?.title,
                        category: post?.category[0],
                        dateTime: `${date}  |  ${time}`
                    }
                    return <Link key={post._id} style={{textDecoration: 'none'}} to={`/vijesti/${post.alias}`}><OnlyTextNews {...DATA} /></Link>
                })}
                {/* <OnlyTextNews {...news1} />
                <OnlyTextNews {...news1} />
                <OnlyTextNews {...news1} />
                <OnlyTextNews {...news1} />
                <OnlyTextNews {...news1} />
                <OnlyTextNews {...news1} /> */}
            </RightColumnWrapper>
        </div>
    )
}
