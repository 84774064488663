import React from 'react'
import styled from 'styled-components';
import {Col, Row, Container} from 'reactstrap'
import SingleNews, {SingleNewsWrapper, NewsImg, TopNewsText, SingleNewsRowWrapper} from '../single_news/SingleNews'
import { BleedWrapper, RowWrapper } from '../../styled/Layout.styled';
// import basketballImg from '../../../assets/top_news/basketball.png'
// import kanalImg from '../../../assets/top_news/kanal.png'
// import cakeImg from '../../../assets/top_news/cake.png'
import { NewsHeading, NewsDateTime, NewsColWrapper } from '../single_news/NewsText';
import { SmallRedLink } from '../../styled/Links.styled';
import { API_ENDPOINT } from '../../../constants';
import { Link } from 'react-router-dom';
import HorizontalNews from '../single_news/HorizontalNews';

// const news1 = {
//     img: cakeImg,
//     heading: "22 Holiday Destinations We're All Seriously Lusting After In 2022",
//     category: "Travel",
//     dateTime: "30.10.2020 | 22:48"
// }

// const news2 = {
//     img: basketballImg,
//     heading: "Is the Pilbara the oldest place on Earth?",
//     category: "Travel",
//     dateTime: "30.10.2020 | 22:48"
// }

// const news3 = {
//     img: kanalImg,
//     heading: "Why the tiny house is perfect for now",
//     category: "Design",
//     dateTime: "30.10.2020 | 22:48"
// }

// const news4 = {
//     img: cakeImg,
//     heading: "Stunning cabins and hideaways around the world",
//     category: "Design",
//     dateTime: "30.10.2020 | 22:48"
// }

export const TopNewsWrapper = styled.div`
    margin-top: 10px;
`

const TopSingleNews = styled(SingleNews)`
    @media (max-width: 595px) {
        ${SingleNewsRowWrapper}{
        padding: 0;
        }
        ${NewsColWrapper}{
            justify-content:center;
        }
        ${NewsImg}{
            width: 70px;
            height: 50px;
        }
        ${TopNewsText}{
            ${NewsHeading}{
                font-size:13px;
            }
        
            ${RowWrapper}{
                display:none;
            }
        }
    }
`


export default function TopNews({ className = "top-news", posts, categoryName}) {
    // const [featuredPosts, setFeaturedPosts] = useState([]);

    // useEffect(() => {
    //     setFeaturedPosts([]);
    //     fetch(API_ENDPOINT + '/getFeaturedNews', {method: 'GET'})
    //     .then(res => res.json())
    //     .then(data => setFeaturedPosts(data))
    //     .catch(err => console.log(err));
    // }, []);

    return (
        <div className={className}>
            
            <TopNewsWrapper>
                <BleedWrapper>
                    {posts && posts.length > 0 && posts.map(post => {
                        const ts = new Date(post?.datetime * 1000);
                        const date = ts.toLocaleDateString('sr-SR');
                        const time = ts.toLocaleTimeString('sr-SR', {hour: '2-digit', minute: '2-digit'});
                        const DATA = {
                            // img: API_ENDPOINT+post?.images[0],
                            heading: post?.title,
                            category: post?.category,
                            dateTime: `${date}  |  ${time}`
                        }
                        return <Col key={post._id} xl={3} md={6} sm={6} xs={6}>
                        <Link key={post?._id} style={{textDecoration: 'none'}} to={`/vijesti/${post?.alias}`}>
                            <HorizontalNews className='homePageHorizontalNews' img={API_ENDPOINT+post?.images[0]} {...DATA} />
                            {/* <TopSingleNews {...DATA} /> */}
                            </Link>
                    </Col>
                    })}
                   
                    {/* <Col xl={3} md={6} sm={6} xs={6}>
                        <TopSingleNews {...news2} />
                    </Col>
                    <Col xl={3} md={6} sm={6} xs={6}>
                        <TopSingleNews {...news3} />
                    </Col>
                    <Col xl={3} md={6} sm={6} xs={6}>
                        <TopSingleNews {...news4} />
                    </Col>  */}
                </BleedWrapper>
            </TopNewsWrapper>
        </div>
    )
}
