import React from 'react'
import styled from 'styled-components';
import { RowWrapper } from '../../styled/Layout.styled';

const Username = styled.p`
    font-size: 1.25rem;
    font-weight: ${props => props.theme.boldWeight};
`

export const CommentWrapper = styled.div`
    background-color: ${props => props.theme.whiteColor};
    margin-bottom: 10px;
    padding: 23px;
`

const DateTime = styled.p`
    font-size: 0.875rem;
    color: ${props => props.theme.greyColor};
`

const CommentText = styled.p`
    font-size: 0.938rem;
    margin-top: 23px;
`

export default function CommentResponseBase({ children, username, dateTime, text, className="comment-wrapper" }) {
    return (
        <div className={className}>
            <CommentWrapper>
                <RowWrapper justify="space-between">
                    <Username>{username}</Username>
                    <DateTime>{dateTime}</DateTime>
                </RowWrapper>
                <CommentText>
                    {text}
                </CommentText>
                <RowWrapper justify="end">
                    {children}
                </RowWrapper>
            </CommentWrapper>
        </div>
    )
}
