import React, {useState, useEffect, useRef} from 'react'
import styled from 'styled-components';
import {  ColWrapper } from '../../styled/Layout.styled';
import {Col, Row, Container} from 'reactstrap'
import MainHeading, { MainHeadingWord } from '../global/MainHeading';
import { MediaIcons } from '../top_page/Header';
import { RedButton } from '../../styled/Button.styled';
import { ShortLine } from '../../styled/ShortLine.Styled';
//import img
import fbIco from '../../../assets/footer_content/facebook.svg'
import instaIco from '../../../assets/footer_content/instagram.svg'
import youtubeIco from '../../../assets/footer_content/youtube.svg'

import logo from '../../../assets/logo/hit-logo.svg'
import { Link } from 'react-router-dom';
import { API_ENDPOINT } from '../../../constants';

const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

const FooterText = styled.p`
    font-size: 0.875rem;
    color: ${props => props.theme.darkColor};
    margin-top: ${props => props.marginTop};
`

const FooterWrapper = styled.div`
    background-color: ${props => props.theme.whiteColor};
    padding-bottom: 36px;
    padding-top: 34px;
`
const FooterMediaIcons = styled(MediaIcons)`
    margin-top: 11px;               
    a{
        height: 24px;
        &:not(:last-child){
            margin-right: 51px;
        }

        img {
            height: 100%;
            // width: 100%;
        }
    }
`

const FooterMiniHeading = styled.p`
    font-size: 1.125rem;
    color: ${props => props.theme.darkColor};
    font-weight: ${props => props.theme.boldWeight};
    text-transform: uppercase;
    margin-top: 10px;
`

const FooterUl = styled.ul`
    li{
        
        list-style-type: none;
    }
    li:not(:first-child){
        margin-top: 17px;
    }
`

const FooterEmailInput = styled.input.attrs(props => ({
    type: "email",
    placeholder: "Unesite vašu e-mail adresu",
    required: true,
}))`
    background-color: ${props => props.theme.lightGreyColor};
    width: 100%;
    height: 40px;
    border: none;
    margin-top: 27px;
    padding-left: 20px;
    ::placeholder { 
        color: ${props => props.theme.greyColor};
        padding-left: 15px;
    }
`
const FooterRedButton = styled(RedButton)`
    margin-top: 18px;
    
`

const ButtonWrapper = styled.div`
    width: 100%;    
    display: flex;
    justify-content: end;
`

const FooterCopyright = styled(Row)`
    padding-top: 30px;
    border-top: 1px solid ${props => props.theme.lightGreyColor};
`

const FooterWrapperCol = styled(Col)`
    padding-bottom: 35px;
    
`
const FooterMainHeading = styled(MainHeading)`
    ${MainHeadingWord}{
        @media (max-width: 1200px) {
            font-size: 50px;
        }
    }
`
export default function Footer() {
    const [categories, setCategories] = useState([]);
    const emailRef = useRef();
    const [err, setErr] = useState(null);
    const [msg, setMsg] = useState(null);

    useEffect(() => {
        setCategories([]);
        fetch(API_ENDPOINT + '/getFooterCategories', {method: 'GET'})
        .then(res => res.json())
        .then(data => setCategories(data));
    }, []);

    // console.log(categories);

    const subscribe = () => {
        setErr(null);
        setMsg(null);
        const email = emailRef.current.value;
        if (!email || email === '' || !email.includes('@') || !validateEmail(email)) {
            setErr('Morate unijeti validnu email adresu!');
            return;
        }
        fetch(API_ENDPOINT + '/addNewEmail', {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({email})
        }).then(res => res.json())
        .then(data => {
            if (data.error) {
                setErr(data.error);
            } else if (data.message) {
                setMsg(data.message);
            }
            setTimeout(() => {
                setErr(null);
                setMsg(null);
                emailRef.current.value = '';
            }, 2500)
        })
    }

    return (
        <div>
            <FooterWrapper>
                <Container>

                    <Row>
                        <FooterWrapperCol lg={3} md={6}>
                            <ColWrapper>

                                <Link to='/'><img loading='lazy' style={{marginBottom: '10px'}} alt='logo' src={logo} /></Link>

                                <FooterText style={{marginBottom: '10px'}}>Najnovije vijesti iz Brčko Distrikta, regiona, sporta, magazina i mnogo više.
                                </FooterText>
                                <FooterText>Na vašim mobilnim telefonima nas možete
                                    pratiti putem mobilne verzije i aplikacija.</FooterText>
                                <FooterText marginTop="15px">Pratite nas:</FooterText>
                                <FooterMediaIcons>
                                    <a style={{textDecoration: 'none'}} title='Facebook' href='https://www.facebook.com/radiotelevizijahit/' target='_blank' ><img loading='lazy' src={fbIco} /></a>
                                    <a style={{textDecoration: 'none'}} title='Instagram' href='' target='_blank' ><img loading='lazy' src={instaIco} /></a>
                                    <a style={{textDecoration: 'none'}} title='Youtube' href='https://www.youtube.com/c/RTVHITOfficial' target='_blank' ><img loading='lazy' src={youtubeIco} /></a>
                                    {/* <img loading='lazy' src={instaIco} />
                                    <img loading='lazy' src={youtubeIco} /> */}
                                </FooterMediaIcons>
                            </ColWrapper>
                        </FooterWrapperCol>

                        <FooterWrapperCol lg={6} md={12}>
                            <FooterMiniHeading>Kategorije</FooterMiniHeading>
                            <ShortLine red />
                            <Row>

                                <Col>
                                    <FooterUl>
                                        {categories.length && categories.map((cat, i) => {
                                            if (i > 4) {
                                                return;
                                            }
                                            return <li key={cat._id}><Link style={{textDecoration: 'none'}} to={`/kategorije/${cat.alias}`}>{cat.categoryName}</Link></li>
                                                
                                        })}
                                        {/* <li>Aktuelno</li>
                                        <li>Sport</li>
                                        <li>Magazin</li>
                                        <li>Zanimljivosti</li>
                                        <li>Servisne informacije</li> */}
                                    </FooterUl>
                                </Col>
                                <Col>
                                    <FooterUl>
                                        {categories.length && categories.map((cat, i) => {
                                            if (i < 5) {
                                                return;
                                            }
                                            return <li key={cat._id}><Link style={{textDecoration: 'none'}} to={`/kategorije/${cat.alias}`}>{cat.categoryName}</Link></li>
                                        })}
                                        {/* <li>Bosna I Hercegovina</li>
                                        <li>Republika Srpska</li>
                                    <li>Video</li> */}
                                        <li><Link style={{textDecoration: 'none'}} to='/stranica/marketing'>Marketing</Link></li>
                                        <li><Link style={{textDecoration: 'none'}} to='/stranica/kontakt'>Kontakt</Link></li>
                                    </FooterUl>
                                </Col>
                            </Row>
                        </FooterWrapperCol>

                        <FooterWrapperCol lg={3} md={6}>
                            <FooterMiniHeading>NewsLetter</FooterMiniHeading>
                            <ShortLine red />
                            <FooterText>Najnovije vijesti iz Brčko Distrikta, regiona, sporta, magazina i mnogo više.</FooterText>
                            <FooterEmailInput ref={emailRef} />
                            <ButtonWrapper><FooterRedButton style={{cursor: 'pointer'}} onClick={subscribe}>Pošalji</FooterRedButton></ButtonWrapper>
                            {err && <p style={{position: 'relative', top: '15px', color: 'red'}}>{err}</p>}
                            {msg && <p style={{position: 'relative', top: '15px', color: '#4FAC68'}}>{msg}</p>}
                        </FooterWrapperCol>
                    </Row>

                    <FooterCopyright>
                        <Col md={6} lg={9}>
                            <FooterText>Copyright © HIT Televizija Brčko  - 2022. All Rights Reserved.</FooterText>
                        </Col>

                        <Col md={6} lg={3}>
                            <FooterText>Created by nova media.</FooterText>
                        </Col>
                    </FooterCopyright>
                </Container>
            </FooterWrapper >
        </div >
    )
}
