import React from 'react'
import styled from 'styled-components';
import {RowWrapper, ColWrapper } from '../../styled/Layout.styled';
import {Col, Row, Container} from 'reactstrap'
import MainHeading from './MainHeading';
import { Link } from 'react-router-dom';

const RowWrapperSpaceBetween = styled(RowWrapper)`
    justify-content: space-between;
    padding: 0;
    margin: 30px 0px 20px 0px;
    border-bottom: 2px solid ${props => props.theme.darkBorder};
    height: 42px;
    flex-shrink: unset;
`
const SmallLabel = styled.p`
    color: ${props => props.theme.greyColor};
    font-size: 0.875rem;
    
    &:before{
        content:"";
        border: solid ${props => props.theme.redColor};;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        margin-right: 7px;
    }
`

export default function SectionHeading({categoryName, categoryAlias, redHeading, blackHeading, className = "section-heading" }) {
    return (
        <Row className={className}>
            <Col>
            <RowWrapperSpaceBetween >
                <MainHeading redText={redHeading} blackText={blackHeading} />
                <RowWrapper >
                    <Link style={{textDecoration: 'none'}} to={`/kategorije/${categoryAlias}`} ><SmallLabel>Sve vijesti iz {categoryName}</SmallLabel></Link>
                </RowWrapper>
            </RowWrapperSpaceBetween>
            </Col>
        </Row>
    )
}
