import styled from 'styled-components';

export const RedButton = styled.button` 
    padding: 7px 17px;
    font-size: 0.875rem;
    color: ${props => props.theme.whiteColor};
    background-color: ${props => props.theme.redColor};
    font-weight: ${props => props.theme.semiBoldWeight};
    border: none;
`

