import React from 'react'
import styled from 'styled-components';
import NewsText, {NewsHeading, NewsDateTime, NewsColWrapper} from './NewsText'

export const WhiteNewsText = styled(NewsText)`
  ${NewsHeading}{
    color: ${props => props.theme.whiteColor};
    font-size: 30px;
    margin-bottom: 20px;
  }

  ${NewsColWrapper}{
    position: absolute;
    bottom: 1px;
    padding: 0 50px 20px 22px;
    width: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9));
    }

  ${NewsDateTime}{
    color: ${props => props.theme.whiteColor};
  }
`

export const MainNewsWrapper = styled.div`
  position: relative;
  height: ${props => props.h}px;
  margin-bottom: 10px;
  img{
      object-fit: cover;
      width: 100%;
      height:100%;
  }
`

export default function MainSingleNews({h, newsText, newsImg, className="main-news-single"}) {
  return (
   <div className={className}>
    <MainNewsWrapper h={h}>
      <img loading='lazy' src={newsImg} alt='slika' />
      <WhiteNewsText {...newsText} />
    </MainNewsWrapper>
  </div>
  )
}
