import React, {useState, useEffect} from 'react'
import styled from 'styled-components';
import {Col, Row, Container} from 'reactstrap'
import MainHeading from '../components/regular/global/MainHeading'
import MainSingleNews, {MainNewsWrapper} from '../components/regular/single_news/MainSingleNews'
import { NewsHeading, NewsColWrapper } from '../components/regular/single_news/NewsText';
import VerticalNews, { VerticalNewsWrapper, VerticalText } from '../components/regular/single_news/VerticalNews'
import WhiteFrameBanner from '../components/regular/global/WhiteFrameBanner';
import LatestNewsColumn from '../components/regular/global/LatestNewsColumn';
import PopularVideosColumn from '../components/regular/global/PopularVideosColumn';
import Pagination from '../components/pagination/Pagination';
import PopularNewsColumn from '../components/regular/global/PopularNewsColumn';

import DocumentMeta from 'react-document-meta';

import Banner from '../components/banners/Banner';
import Placeholder from '../components/banners/Placeholder';


import {Link} from 'react-router-dom';
import { API_ENDPOINT } from '../constants';


export const MagazineMainSingleNews= styled(MainSingleNews)`
    ${MainNewsWrapper}{
        margin-bottom:20px;
    }
`

export const MagazineVerticalNews = styled(VerticalNews)`

    ${VerticalNewsWrapper}{
        background-color: unset ;
        box-shadow: unset;
        margin-bottom:15px;
    }
    ${VerticalText}{
        ${NewsHeading}{
            font-size: 1.375rem;//
         }

        ${NewsColWrapper}{
            padding-left: 0;
        }
    }
`

export default function Newest() {
    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [count, setCount] = useState(null);

    const [banner1, setBanner1] = useState(null);
    const [banner2, setBanner2] = useState(null);

    useEffect(() => {
        setPosts([]);
        fetch(`${API_ENDPOINT}/getNewestPosts?currentPage=${currentPage}`, {method: 'GET'})
        .then(res => res.json())
        .then(data => {
            setPosts(data.posts);
            setCount(data.count);
        })
        .catch(err => console.log(err))
    }, [currentPage]);

    useEffect(() => {
        fetch(API_ENDPOINT + '/getBanners/CategoryPage', {method: 'GET'})
        .then(res => res.json())
        .then(data => {
            data.forEach(arr => {
                arr.forEach(i => {
                    if (i.position === 'CategoryPage-1') {
                        setBanner1(i);
                    } else if (i.position === 'CategoryPage-2') {
                        setBanner2(i);
                    }
                })
            })
        })
    }, []);

    useEffect(() => {
        if (window) {
            window.scrollTo(0,0)
        }
    } ,[currentPage])
    

    return (
        <div>
            <DocumentMeta title='Najnovije vijesti | RTV HIT' description='Najnovije i najpouzdanije vijesti iz Brčko Distrikta, regiona, BiH i svijeta.' />
            <Container>
                <Row>
                    <Col lg={8} >
                        <Row>
                            <Col>
                            <MainHeading redText='Najnovije' blackText='Vijesti' />
                            {posts.length > 0 && <Link style={{textDecoration: 'none'}} to={`/vijesti/${posts[0]?.alias}`}><MagazineMainSingleNews h={500} newsText={{heading: posts[0]?.title, category: posts[0]?.category[0], dateTime: `${new Date(posts[0]?.datetime * 1000).toLocaleDateString('sr-SR')}  |  ${new Date(posts[0]?.datetime*1000).toLocaleTimeString('sr-SR', {hour: '2-digit', minute: '2-digit'})}`}} newsImg={API_ENDPOINT + posts[0]?.images[0]}/></Link>}
                            </Col>
                        </Row>
                        <Row style={{marginBottom: '20px'}}>
                            {posts.length > 0 && posts.map((post, i) => {
                                if (i === 0) {
                                    return;
                                }
                                const ts = new Date(post?.datetime * 1000);
                                const date = ts.toLocaleDateString('sr-SR');
                                const time = ts.toLocaleTimeString('sr-SR', {hour: '2-digit', minute: '2-digit'});
                                const DATA = {
                                    heading: post?.title,
                                    category: post?.category[0],
                                    dateTime: `${date}  |  ${time}`
                                }
                                return <Col key={post._id} lg={6}><Link style={{textDecoration: 'none'}} to={`/vijesti/${post?.alias}`}><MagazineVerticalNews className='categoryVerticalNews' newsText={DATA} img={API_ENDPOINT + post?.images[0]} /></Link></Col>
                            }
                            )}
                        </Row>
                        <Pagination 
                        currentPage={currentPage} 
                        totalCount={count} 
                        pageSize={15} 
                        onPageChange={page => setCurrentPage(page)} />
                    </Col>

                    <Col lg={4}>
                        <PopularNewsColumn />
                        {/* <WhiteFrameBanner bannerImg={banner1} /> */}
                        {banner1 ? <Banner data={banner1} /> : <Placeholder h='300px' /> }
                        <LatestNewsColumn />
                        {/* <WhiteFrameBanner bannerImg={banner2} /> */}
                        {banner2 ? <Banner data={banner2} /> : <Placeholder h='300px' /> }
                        <PopularVideosColumn />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
