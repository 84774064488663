import React, {useState, useEffect} from 'react'
import Banner from '../../banners/Banner';
import { Col, Row, Container } from 'reactstrap'
import styled from 'styled-components';
import banner from '../../../assets/main_news/banner.png'
import SectionHeading from '../global/SectionHeading'
import { RowWrapper } from '../../styled/Layout.styled';
//components import
import MainMiniNews from './MainMiniNews'
import RightColumn from './RightColumn';
import MainSingleNews from '../single_news/MainSingleNews';

import mainNewsPicture from '../../../assets/main_news/main-news-heading.png'
import { API_ENDPOINT } from '../../../constants';
import { Link } from 'react-router-dom';
import Placeholder from '../../banners/Placeholder';

const SectionHeadingPopularNews = styled(SectionHeading)`
    display:none;

@media (max-width: 992px) {
    display: block;
    
    ${RowWrapper}{
        margin-top:0px;
    }
}
`

const mainNewData = {
    heading: "Pierre-Emerick Aubameyang: Barcelona confirm signing on free transfer",
    category: "Sport",
    dateTime: "30.10.2020.  | 22:48"
}

export default function PopularNews({banner, featured, popular}) {

    return (
        <Row>
            <SectionHeadingPopularNews redHeading="Popular" blackHeading="News" />
            {featured && <Col >
                <Row>
                    <Col md={7} >
                        {/* <MainSingleNews newsText={mainNewData} newsImg={mainNewsPicture} /> */}
                       <Link style={{textDecoration: 'none'}} to={`/vijesti/${featured[0]?.alias}`}><MainSingleNews h={500} newsText={{heading: featured[0]?.title, category: featured[0]?.category[0], dateTime: `${new Date(featured[0]?.datetime * 1000).toLocaleDateString('sr-SR')}  |  ${new Date(featured[0]?.datetime*1000).toLocaleTimeString('sr-SR', {hour: '2-digit', minute: '2-digit'})}`}} newsImg={API_ENDPOINT + featured[0]?.images[0]} /></Link>
                    </Col>
                    <Col md={5}>
                        <MainMiniNews posts={featured.length > 0 && featured} API_ENDPOINT={API_ENDPOINT} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {banner ? <Banner data={banner} h='170px' /> : <Placeholder h='170px' />}
                    </Col>
                </Row>
            </Col>}

            {popular && <Col lg={3} md={12}>
                <RightColumn posts={popular} />
            </Col>}
        </Row>

        )
    
}
