import { useState, useEffect } from 'react';
import {useParams} from 'react-router-dom';
import { Col, Row, Container } from 'reactstrap'
import LatestNewsColumn from '../components/regular/global/LatestNewsColumn';
import MainHeading from '../components/regular/global/MainHeading';
import PopularNewsColumn from '../components/regular/global/PopularNewsColumn';
import PopularVideosColumn from '../components/regular/global/PopularVideosColumn';
import WhiteFrameBanner from '../components/regular/global/WhiteFrameBanner';
import { API_ENDPOINT } from '../constants';

import DocumentMeta from 'react-document-meta';

import Banner from '../components/banners/Banner';
import Placeholder from '../components/banners/Placeholder';

import {NewsTextWrapper, RegularText} from './SingleNewsPage';

const CustomPage = () => {
    const params = useParams();
    const customPage = params.customPage;

    const [banner1, setBanner1] = useState(null);
    const [banner2, setBanner2] = useState(null);

    const [page, setPage] = useState(null);

    useEffect(() => {
        setPage(null);
        fetch(API_ENDPOINT + `/getCustomPage/${customPage}`, {method: 'GET'})
        .then(res => res.json())
        .then(data => setPage(data))
        .catch(err => console.log(err));
    }, [customPage]);

    useEffect(() => {
        fetch(API_ENDPOINT + '/getBanners/CustomPage', {method: 'GET'})
        .then(res => res.json())
        .then(data => {
            data.forEach(arr => {
                arr.forEach(i => {
                    if (i.position === 'CustomPage-1') {
                        setBanner1(i);
                    } else if (i.position === 'CustomPage-2') {
                        setBanner2(i);
                    }
                })
            })
        })
    }, []);

    useEffect(() => {
        if (window) {
            window.scrollTo(0,0)
        }
    } ,[customPage]);

    const createMarkup = () => {
        return {__html: page?.details}
    }

    return <div>
        <DocumentMeta title={page && page.title + ' | RTV HIT'} description='' />
        <Container>
            <Row>
                <Col lg={8}>
                    <MainHeading redText={page && page.title} />
                    {page && <NewsTextWrapper><RegularText dangerouslySetInnerHTML={createMarkup()}a>
                    </RegularText></NewsTextWrapper>}
                </Col>
                <Col lg={4}>
                    <LatestNewsColumn />
                    {/* <WhiteFrameBanner bannerImg={banner1} /> */}
                    {banner1 ? <Banner data={banner1} /> : <Placeholder h='300px' /> }
                    <PopularNewsColumn />
                    {/* <WhiteFrameBanner bannerImg={banner1} /> */}
                    {banner2 ? <Banner data={banner2} /> : <Placeholder h='300px' /> }
                    <PopularVideosColumn />
                </Col>
            </Row>
        </Container>
    </div>
}

export default CustomPage;