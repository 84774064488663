import './HorizontalNews.css';

import { SmallRedLink } from '../../styled/Links.styled';

export default function HorizontalNews({className, img, category, dateTime, heading}) {
    return (
        <div className={className}>
            <div className='horizontalNewsImgDiv'>
                <img loading='lazy' alt='slika' src={img} />
            </div>
            <div className='horizontalNewsTextDiv'>
                <div className='horizontalNewsHeadingDiv'>
                    {heading}
                </div>
                <div className='horizontalNewsCatAndDateDiv'>
                    <span className='horizonalNewsCategorySpan'><SmallRedLink>{category}</SmallRedLink></span>
                    <span className='horizonalNewsDateSpan'>{dateTime}</span>
                </div>
            </div>
        </div>
    )
}