import React from 'react'
import styled from 'styled-components';
import { RowWrapper, ColWrapper } from '../../styled/Layout.styled';
import { SmallRedLink } from '../../styled/Links.styled';

export const NewsHeading = styled.p`
    font-weight: ${props => props.theme.boldWeight};
    font-size: 16px;
    color: ${props => props.theme.darkColor};
    text-align: left;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3; 
    -webkit-box-orient: vertical;
`

export const NewsDateTime = styled.p`
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1; 
    -webkit-box-orient: vertical;
    color: ${props => props.theme.greyColor};
    margin-left: 20px;
`

export const NewsColWrapper = styled(ColWrapper)`

`

export default function NewsText({ category, dateTime, heading, className = "main-news" }) {
    return (
        <div className={className}>
            <NewsColWrapper>
                <NewsHeading>{heading}</NewsHeading>
                <RowWrapper style={{ marginTop: "auto" }}>
                    <SmallRedLink>
                        {category}
                    </SmallRedLink>
                    <NewsDateTime>
                        {dateTime}
                    </NewsDateTime>
                </RowWrapper>
            </NewsColWrapper>
        </div>
    )
}
