import React, {useState, useEffect} from 'react'
import styled from 'styled-components';
import { Col, Row, Container } from 'reactstrap'
import MainHeading from './MainHeading';
import { NewsHeading, NewsColWrapper } from '../single_news/NewsText';
import SingleBackgroundNews from '../single_news/SingleBackgroundNews';
import VerticalNews, { VerticalNewsWrapper, VerticalText } from '../single_news/VerticalNews';

import LoadingSpinner from '../../spinner/LoadingSpinner';

import { API_ENDPOINT } from '../../../constants';
import { Link } from 'react-router-dom';

const MagazineVerticalNews = styled(VerticalNews)`
    ${VerticalNewsWrapper}{
        background-color: unset ;
        box-shadow: unset;
        margin-bottom:15px;
    }
    ${VerticalText}{
        ${NewsHeading}{
            font-size: 1.375rem;//
         }

        ${NewsColWrapper}{
            padding-left: 0;
        }
    }
`

const MagazinMiniVerticalNews = styled(MagazineVerticalNews)`
    ${VerticalText}{
        ${NewsHeading}{
            font-size: 0.875rem;//
         }
    }
`



const mainNews1 = {
    heading: "Pierre-Emerick Aubameyang: Barcelona confirm signing on free transfer",
    category: "Sport",
    dateTime: "30.10.2020.  | 22:48"
}

const miniVerticalNews = {
    heading: "Buying A Dress? Why Not A Farm, While You're At It?",
    category: "Bijeljina",
    dateTime: "30.10.2020."
}

const miniVerticalNews2 = {
    heading: "Can Teams Replicate What Tom Brady Brought to the NFL?",
    category: "Bijeljina",
    dateTime: "30.10.2020."
}

export default function PopularNewsColumn() {
    const [popularPosts, setPopularPosts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setPopularPosts([]);
        setLoading(true);
        fetch(API_ENDPOINT + '/getMiniPopularNews', {method: 'GET'})
        .then(res => res.json())
        .then(data => {setPopularPosts(data); setLoading(false);})
        .catch(err => console.log(err));
    }, []);

    // console.log(popularPosts);

    return (
        <div>
            <MainHeading redText="Popularno" />
            {loading ? <LoadingSpinner /> : <div>
            {popularPosts.length > 0 && popularPosts.map((post, i) => {
                if (i === 0) {
                    const ts = new Date(post?.datetime * 1000);
                    const date = ts.toLocaleDateString('sr-SR');
                    const time = ts.toLocaleTimeString('sr-SR', {hour: '2-digit', minute: '2-digit'});
                    const DATA = {
                        heading: post?.title,
                        category: post?.cat[0].categoryName,
                        dateTime: `${date}  |  ${time}`
                    }
                    return <Row key={post._id}>
                <Col>
                    <Link style={{textDecoration: 'none'}} to={`/vijesti/${post?.alias}`}><SingleBackgroundNews h={240} newsText={DATA} src={API_ENDPOINT+post?.images[0]} /></Link>
                </Col>
            </Row>
                }
            })}
            <Row>
                {popularPosts.length > 0 && popularPosts.map((post, i) => {
                    if (i > 0) {
                        const ts = new Date(post?.datetime * 1000);
                        const date = ts.toLocaleDateString('sr-SR');
                        // const time = ts.toLocaleTimeString('sr-SR', {hour: '2-digit', minute: '2-digit'});
                        const DATA = {
                            heading: post?.title,
                            category: post?.cat[0].categoryName,
                            dateTime: date
                        }
                        return <Col key={post._id}>
                    <Link style={{textDecoration: 'none'}} to={`/vijesti/${post?.alias}`}><MagazinMiniVerticalNews className='miniVerticalNews' newsText={DATA} img={API_ENDPOINT+post?.images[0]} /></Link>
                </Col>
                    }
                })}

            </Row>

            </div>}
            
            {/* <Row >
                
                <Col >
                    <MagazinMiniVerticalNews newsText={miniVerticalNews2} img={kanalSmall} />
                </Col>
            </Row> */}
        </div>
    )
}
