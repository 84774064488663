import React from 'react'
import styled from 'styled-components';
import CommentResponseBase, { CommentWrapper } from './CommentResponseBase';

const ResponseArea = styled(CommentResponseBase)`
    ${CommentWrapper}{
        width: 87%;
    }
`

export default function Response({ responseData }) {
    return (
        <div>
            <ResponseArea {...responseData} />
        </div>
    )
}
