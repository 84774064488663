import React from 'react'
import styled from 'styled-components';
import VerticalNews from '../single_news/VerticalNews';
import { Col, Row } from '../../styled/Layout.styled';
import { VerticalNewsWrapper, VerticalText } from '../single_news/VerticalNews';
import { NewsHeading, NewsColWrapper } from '../single_news/NewsText';
import playButton from '../../../assets/magazine_page/play_button.svg'

const SmallPlayButton = styled.img`
    width: 24px !important;
    height:24px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

const SmallVerticalNews = styled(VerticalNews)`
    ${VerticalNewsWrapper}{
        background-color: unset ;
        box-shadow: unset;
        margin-bottom:15px;
    }
    ${VerticalText}{
        ${NewsHeading}{
            font-size: 0.875rem;
            margin-bottom: 10px;
        }

        ${NewsColWrapper}{
            padding-left: 0;
            padding-bottom: 0;
        }
    }
`

export default function VerticalShortVideoNews({ img, newsText }) {
    return (
        <div>
            <SmallVerticalNews className='miniVerticalNews' newsText={newsText} img={img}>
                <SmallPlayButton src={playButton} />
            </SmallVerticalNews>
        </div>
    )
}
