import styled from 'styled-components';
import React from 'react';

export const BannerImg = styled.img`
    padding: 0;
    width: 100%;
    height: auto;
    max-height: ${props =>props.h}px;
`
export default function BannerStyle(props) {
    return (
        <>
            <a target='_blank' href={props.link}><BannerImg h={props.h} src={props.src} /></a>
        </>
    )
}
