import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const RedLink = styled(Link)`
    text-decoration: none;
    padding: 3px 10px;
    font-size: 14px;
    background-color: ${props => props.theme.redColor};
    color: ${props => props.theme.whiteColor};
    border: 0;
    &:hover{
        cursor: pointer;
    }
    
    white-space: nowrap;
`
export const WhiteLink = styled(RedLink)`
    background-color: ${props => props.theme.whiteColor};
    color: ${props => props.theme.darkColor};
`

export const SmallRedLink = styled(RedLink)`
    font-size: 12px;
    padding: 0px 5px;
`