import React, {useRef, useState} from 'react'
import styled from 'styled-components';
import { API_ENDPOINT } from '../../../constants';

import './Comment.css';

import CommentResponseBase from './CommentResponseBase';
const ResponseButton = styled.button`
    color: ${props => props.theme.redColor};
    font-weight: ${props => props.theme.boldWeight};
    font-size: 1.063rem;
    border: none;
    background: transparent;
    margin-top: 16px;
`

export default function Comment({commentData, commentId, setUpd}) {
    const [showForm, setShowForm] = useState(false);
    const crNameRef = useRef();
    const crReplyRef = useRef();

    const [err, setErr] = useState(null);

    const handleSendReply = async() => {
        setErr(null);
        const name = crNameRef.current.value;
        const comment = crReplyRef.current.value;
        if (!name || !comment || name === '' || comment === '') {
            setErr('Sva polja su obavezna!');
            return;
        }
        const obj = {
            name,
            comment,
            commentId 
        }

        fetch(API_ENDPOINT + '/addNewCommentReply', {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(obj)
        }).then(res => res.json())
        .then(data => {
            if (data?.error) {
                setErr(data.error);
                setTimeout(() => {setErr(null)}, 3000);
            } else {
                setUpd(prev => prev+1);
            }
        })
        .catch(err => {
            setErr('Došlo je do greške. Vaš odgovor nije sačuvan.')
        })
    }

    return (
        <div style={{backgroundColor: 'white', marginBottom: '10px'}}>
            <CommentResponseBase {...commentData}>
                <ResponseButton style={{cursor: 'pointer'}} onClick={() => setShowForm(true)}>Odgovori</ResponseButton>
            </CommentResponseBase>
                {showForm && <div className='addReplyCommentDiv'>
                    <div>
                        <input required ref={crNameRef} type='text' placeholder='Vaše ime' />
                        <textarea required ref={crReplyRef}  placeholder='Vaš odgovor' />
                    </div>
                    {err && <p style={{margin: '5px', color: 'red'}}>{err}</p>}
                    <button type='button' onClick={() => {setShowForm(false); setErr(null)}}>Odustani</button>
                    <button id='sendReply' onClick={handleSendReply}>Pošalji</button>
                </div>}
        </div>
    )
}
