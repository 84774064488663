import './PhotoModal.css';

import {MdClose} from 'react-icons/md';

const PhotoModal = ({src, close}) => {
    return (
        <div onClick={() => close()} className="photoModalBackdrop">
            <div onClick={(e) => e.stopPropagation()} className='photoModalModal' >
                <img loading='lazy' src={src} alt='slika' />
                <MdClose onClick={() => close()} className='photoModalCloseIcon' />
            </div>
        </div>
    )
}

export default PhotoModal;