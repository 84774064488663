import React from 'react'
import styled from 'styled-components';
import {Col, Row, Container} from 'reactstrap'
import VerticalNews, { VerticalText, VerticalNewsWrapper } from '../single_news/VerticalNews';
import { NewsHeading, NewsColWrapper } from '../single_news/NewsText';

//img import
import football from '../../../assets/sport_content/football.png'
import { Link } from 'react-router-dom';
import { API_ENDPOINT } from '../../../constants';

const news1 = {
    heading: "Shy Stuart the Dobermann is ‘new dog’ after starting Canicross",
    category: "Republika Srpska",
    dateTime: "30.10.2020.  | 22:48"
}

export const SportVerticalNews = styled(VerticalNews)`
 
 ${VerticalText}{
        ${NewsColWrapper}{
            padding-left: 0px;
        }
        ${NewsHeading}{
            font-size: 16px; 
        }
    }

    ${VerticalNewsWrapper}{
        min-width: unset;
        background: unset;
        box-shadow: unset;
    }
`

export const PaddingRow = styled(Row)`
    margin-bottom: 20px;
`

export default function SportContentMainRow({posts}) {
    return (
        <div>
            <PaddingRow>
                {posts && posts.length > 0 && posts.map(post => {
                    const ts = new Date(post?.datetime * 1000);
                    const date = ts.toLocaleDateString('sr-SR');
                    const time = ts.toLocaleTimeString('sr-SR', {hour: '2-digit', minute: '2-digit'});
                    const DATA = {
                        heading: post?.title,
                        category: post?.category,
                        dateTime: `${date}  |  ${time}`
                    }
                    return <Col key={post._id} md={6} lg={3}>
                    <Link style={{textDecoration: 'none'}} to={`/vijesti/${post?.alias}`}><SportVerticalNews className='smallVerticalNews' img={API_ENDPOINT+post?.images[0]} newsText={DATA} /></Link>
                </Col>
                })}
                {/* <Col md={4} lg={3}>
                    <SportVerticalNews img={football} newsText={news1} />
                </Col>
                <Col md={4} lg={3}>
                    <SportVerticalNews img={football} newsText={news1} />
                </Col>
                <Col md={4} lg={3}>
                    <SportVerticalNews img={football} newsText={news1} />
                </Col>
                <Col md={4} lg={3}>
                    <SportVerticalNews img={football} newsText={news1} />
                </Col> */}
            </PaddingRow>
        </div>
    )
}
