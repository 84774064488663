import React, {useState, useEffect, useRef} from 'react'
import { Container } from 'reactstrap'
import PopularNews from '../components/regular/popular_news/PopularNews';
import MagazineLatest from '../components/regular/magazine_latest/MagazineLatest';
import ExclusiveContent from '../components/regular/exclusive_content/ExclusiveContent';
import SportContent from '../components/regular/sport_content/SportContent';
import VideoContent from '../components/regular/video_content/VideoContent';
import { API_ENDPOINT } from '../constants';
// import useSeen from '../hooks/useSeen';
import Banner from '../components/banners/Banner';
import Placeholder from '../components/banners/Placeholder';

import DocumentMeta from 'react-document-meta';

/*
    From API:
posts[0] => featured
posts[1] => popular
posts[2] => video
posts[3 - ...] => other categories (without video), sorted by position
*/

export default function Home() {
    const [posts, setPosts] = useState([]);
    const [banner1, setBanner1] = useState(null);
    const [banner2, setBanner2] = useState(null);
    const [banner3, setBanner3] = useState(null);

    useEffect(() => {
        setPosts([]);
        fetch(API_ENDPOINT + '/getHomePageNews', {method: 'GET'})
        .then(res => res.json())
        .then(data => {
            setPosts(data)
        })
        .catch(err => console.log(err))
    }, []);

    useEffect(() => {
        fetch(API_ENDPOINT + '/getBanners/HomePage', {method: 'GET'})
        .then(res => res.json())
        .then(data => {
            data.forEach(arr => {
                arr.forEach(i => {
                    if (i.position === 'HomePage-1') {
                        setBanner1(i);
                    } else if (i.position === 'HomePage-2') {
                        setBanner2(i);
                    } else if (i.position === 'HomePage-3') {
                        setBanner3(i);
                    }
                })
            })
        })
    }, []);

    useEffect(() => {
        if (window) {
            window.scrollTo(0,0)
        }
    } ,[])

    return (
        <div>
            <DocumentMeta title='Početna | RTV HIT' description='Najpouzdanije i najnovije vijesti iz Brčko Distrikta, regiona, BiH i svijeta.' />
            <Container>
                {posts[0] && posts[1] && <PopularNews banner={banner1 && banner1} featured={posts[0]} popular={posts[1]} />}
                {posts[3] && <MagazineLatest posts={posts[3]} section={posts[3][0].category === 'Brčko Distrikt' ? 'Brčko' : posts[3][0].category} categoryName={posts[3][0].category} categoryAlias={posts[3][0].categoryAlias} />}
                {posts[4] && <ExclusiveContent posts={posts[4]} section={posts[4][0].category === 'Brčko Distrikt' ? 'Brčko' : posts[4][0].category} categoryName={posts[4][0].category} categoryAlias={posts[4][0].categoryAlias}  />}
                {banner2 ? <Banner data={banner2} /> : <Placeholder h='200px' />}
                {posts[5] && <SportContent posts={posts[5]} section={posts[5][0].category === 'Brčko Distrikt' ? 'Brčko' : posts[5][0].category} categoryName={posts[5][0].category} categoryAlias={posts[5][0].categoryAlias}  />}
                {posts[6] &&<MagazineLatest posts={posts[6]} section={posts[6][0].category === 'Brčko Distrikt' ? 'Brčko' : posts[6][0].category} categoryName={posts[6][0].category} categoryAlias={posts[6][0].categoryAlias}  />}
            </Container>

            {posts[2] && <VideoContent posts={posts[2]} categoryName='Video' categoryAlias='video' />}

            <Container>
                {posts[7] && <ExclusiveContent posts={posts[7]} section={posts[7][0].category === 'Brčko Distrikt' ? 'Brčko' : posts[7][0].category} categoryName={posts[7][0].category} categoryAlias={posts[7][0].categoryAlias}  />}
                {posts[8] && <SportContent posts={posts[8]} section={posts[8][0].category === 'Brčko Distrikt' ? 'Brčko' : posts[8][0].category} categoryName={posts[8][0].category} categoryAlias={posts[8][0].categoryAlias}  />}
                {banner3 ? <Banner data={banner3} /> : <Placeholder h='200px' />}
                {posts[9] && <MagazineLatest posts={posts[9]} section={posts[9][0].category === 'Brčko Distrikt' ? 'Brčko' : posts[9][0].category} categoryName={posts[9][0].category} categoryAlias={posts[9][0].categoryAlias}  />}
                {posts[10] && <ExclusiveContent posts={posts[10]} section={posts[10][0].category === 'Brčko Distrikt' ? 'Brčko' : posts[10][0].category} categoryName={posts[10][0].category} categoryAlias={posts[10][0].categoryAlias}  />}
            </Container>
        </div>
    )
}
