import React from 'react'
import { Children } from 'react/cjs/react.production.min';
import styled from 'styled-components';
import { SmallRedLink } from '../../styled/Links.styled';
import NewsText, { NewsHeading, NewsDateTim, NewsColWrapper } from './NewsText';

import './VerticalNews.css';

export const VerticalNewsWrapper = styled.div`
  background-color: ${props => props.theme.whiteColor};
  box-shadow: 0px 6px 12px rgb(0 0 0 / 10%);
  max-width: 443px;
  height: ${props=> props.h}px;
    img{
        object-fit: cover; 
        width: 100%;
        height: 170px;
    }
`

export const VerticalText = styled(NewsText)`
  ${NewsHeading}{
    font-size: 25px;
    margin-bottom: 7px;
  }

  ${NewsColWrapper}{
    height: 100%;
    // padding-left: 20px;
    // padding-bottom: 15px;
  }
`

export const ImgWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export default function VerticalNews({img, newsText, className, children}) {
  return (
    <div className={className}>
      <div>
        <img loading='lazy' src={img} alt='slika' />
        {children && <span id='verticalNewsChildrenSpan'>{children}</span>}
        <div className='verticalNewsTextContainer'>
          <div className='verticalNewsTextDiv'>
            <div className='verticalNewsHeadingDiv'>
              {newsText.heading}
            </div>
            {newsText.category && <div className='verticalNewsCatAndDateDiv'>
              <span><SmallRedLink>{newsText.category}</SmallRedLink></span>
              <span className='verticalNewsDateSpan'>{newsText.dateTime}</span>
            </div>}
          </div>
          {/* <VerticalText {...newsText} color='black' /> */}
        </div>
      </div>
    </div>
  )
}

{/* <VerticalNewsWrapper h={h}>

<ImgWrapper>
  <img loading='lazy' src={img} />
  {children}
</ImgWrapper>

<VerticalText {...newsText} color="black" />
</VerticalNewsWrapper> */}


