import React, {Suspense} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Container } from 'reactstrap';
import GlobalStyle from './components/styled/GlobalStyle.styled';
import { ThemeProvider } from 'styled-components';
import Top from './components/regular/top_page/Top';
// import TopNews from './components/regular/top_page/TopNews';
import Footer from './components/regular/footer/Footer';
import Featured from './components/regular/top_page/Featured';

import Home from './pages/Home';
import MagazineNews from './pages/MagazineNews'
import SingleNewsPage from './pages/SingleNewsPage';
import Newest from './pages/Newest';
import CustomPage from './pages/CustomPage';
import Search from './pages/Search';

import './App.css';


const theme = {
  whiteColor: "#FFFFFF",
  lightGreyColor: "#F5F5F5",
  greyColor: "#7A7A7A",
  darkColor: "#222631",
  yellowColor: "#EECF13",
  redColor: "#EC3535",
  darkBorder: "#E4E4E4",


  boldWeight: 700,
  semiBoldWeight: 600,
  extraBoldWeight: 800,
  
  pictureAndFrameHeight: "90px",
};

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <GlobalStyle />
          <Top />
          <Container>
            {/* <TopNews /> */}
            <Featured />
          </Container>
          <Switch>
            <Route exact path="/"><Home /></Route>
            <Route exact path='/najnovije-vijesti'><Newest /></Route>
            <Route exact path='/stranica/:customPage'><CustomPage /></Route>
            <Route exact path='/pretraga/:term'><Search /></Route>
            <Route exact path="/kategorije/:category"><MagazineNews /></Route>
            <Route exact path="/vijesti/:alias"><SingleNewsPage /></Route>
          </Switch>
          <Footer />
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
