import React from 'react'
import styled from 'styled-components';

export const BannerImg = styled.div`
    img{
        margin: 31px auto;
        max-width: 336px;
        width: 100%;
        height: auto;
    }
    display: flex;
    background-color: ${props => props.theme.whiteColor};
    box-shadow: 0px 6px 12px rgb(0 0 0 / 10%);
`

export default function WhiteFrameBanner({bannerImg}) {
  return (
    <div className="white-frame-banner">
        <BannerImg>
            <img loading='lazy' src= {bannerImg}/>
        </BannerImg>
    </div>
  )
}
