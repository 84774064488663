import React from 'react'
import styled from 'styled-components';
import SingleNews from '../single_news/SingleNews';
import { Col, Row } from 'reactstrap';

import { Link } from 'react-router-dom';


import basketball from '../../../assets/main_news/heading-mini-news.png'
import interview from '../../../assets/main_news/interview.png'
import outdoorMeeting from '../../../assets/main_news/meeting-outdoor.png'
import HorizontalNews from '../single_news/HorizontalNews';

const miniColumn1 = {
    img: outdoorMeeting,
    heading: "Stunning cabins and hideaways around the world",
    category: "Culture",
    dateTime: "30.10.2020.  | 22:48"
}

export const HeadingMiniNews = styled.div`
    position: relative;
    height: 150px;

    img{
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    p{
        position: absolute;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* number of lines to show */
        line-clamp: 3; 
        -webkit-box-orient: vertical;
        bottom: 0;
        left:0;
        padding-bottom: 4px;
        padding-left: 4px;
        padding-right: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${props => props.theme.whiteColor};
        font-size:  clamp(13px, 1.5vw, 16px);
        font-weight: ${props => props.theme.boldWeight};
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9));
    }
`
const SingleNewsCol = styled(Col)`
    margin-top: 22px;
`

export default function MainMiniNews({posts, API_ENDPOINT}) {
    return (
        <div>
            <Row >
                <Col>
                <Link style={{textDecoration: 'none'}} to={`/vijesti/${posts[1]?.alias}`}><HeadingMiniNews>
                        <img loading='lazy' src={API_ENDPOINT + posts[1]?.images[0]} />
                        <p>
                            {posts[1]?.title}
                        </p>
                    </HeadingMiniNews></Link>
                </Col>
                <Col>
                <Link style={{textDecoration: 'none'}} to={`/vijesti/${posts[2]?.alias}`}><HeadingMiniNews>
                        <img loading='lazy' src={API_ENDPOINT + posts[2]?.images[0]} />
                        <p>
                            {posts[2]?.title}
                        </p>
                    </HeadingMiniNews></Link>
                </Col>
            </Row>
            <Row>
                <SingleNewsCol>

                {/* const miniColumn1 = {
    img: outdoorMeeting,
    heading: "Stunning cabins and hideaways around the world",
    category: "Culture",
    dateTime: "30.10.2020.  | 22:48"
} */}
                    {posts.length > 0 && posts.map((post, i) => {
                        if (i < 3) {
                            return;
                        }
                        const ts = new Date(post?.datetime * 1000);
                        const date = ts.toLocaleDateString('sr-SR');
                        const time = ts.toLocaleTimeString('sr-SR', {hour: '2-digit', minute: '2-digit'});
                        const DATA = {
                            // img: API_ENDPOINT + post.images[0],
                            heading: post?.title,
                            category: post?.category[0],
                            dateTime: `${date}  |  ${time}`
                        }
                        return <Link key={post._id} style={{textDecoration: 'none'}} to={`/vijesti/${post?.alias}`}>
                            {/* <SingleNews {...DATA} /> */}
                            <HorizontalNews className='featuredHorizontalNews' img={API_ENDPOINT + post.images[0]} {...DATA} />
                        </Link>
                    })}
{/* 
                    <SingleNews {...miniColumn1} />
                    <SingleNews {...miniColumn1} />
                    <SingleNews {...miniColumn1} /> */}

                </SingleNewsCol>
            </Row>
        </div>
    )
}
