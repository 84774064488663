import { useState, useEffect } from 'react';
import {useParams} from 'react-router-dom';
import { Col, Row, Container } from 'reactstrap'
import LatestNewsColumn from '../components/regular/global/LatestNewsColumn';
import MainHeading from '../components/regular/global/MainHeading';
import PopularNewsColumn from '../components/regular/global/PopularNewsColumn';
import PopularVideosColumn from '../components/regular/global/PopularVideosColumn';
import WhiteFrameBanner from '../components/regular/global/WhiteFrameBanner';
import { API_ENDPOINT } from '../constants';

import Banner from '../components/banners/Banner';
import Placeholder from '../components/banners/Placeholder';

import DocumentMeta from 'react-document-meta';

import {Link} from 'react-router-dom';

import {MagazineMainSingleNews, MagazineVerticalNews} from './Newest';

const Search = () => {
    const params = useParams();
    const term = params.term;

    const [posts, setPosts] = useState([]);

    const [banner1, setBanner1] = useState(null);
    const [banner2, setBanner2] = useState(null);

    useEffect(() => {
        setPosts([]);
        fetch(API_ENDPOINT + `/searchAllNews/${term}`, {method: 'GET'})
        .then(res => res.json())
        .then(data => setPosts(data))
        .catch(err => console.log(err));
    }, [term]);

    useEffect(() => {
        fetch(API_ENDPOINT + '/getBanners/CategoryPage', {method: 'GET'})
        .then(res => res.json())
        .then(data => {
            data.forEach(arr => {
                arr.forEach(i => {
                    if (i.position === 'CategoryPage-1') {
                        setBanner1(i);
                    } else if (i.position === 'CategoryPage-2') {
                        setBanner2(i);
                    }
                })
            })
        })
    }, [])

    useEffect(() => {
        if (window) {
            window.scrollTo(0,0)
        }
    } ,[term]);

    return <div>
        <DocumentMeta title='Pretraga | RTV HIT' description={`Svi pronadjeni rezultati za: ${term}`} />
        <Container>
            <Row>
                <Col lg={8}>
                    <MainHeading redText={'Pretraga: '} blackText={term} />
                    {posts.length > 0 && <Link style={{textDecoration: 'none'}} to={`/vijesti/${posts[0]?.alias}`}><MagazineMainSingleNews h={500} newsText={{heading: posts[0]?.title, category: posts[0]?.category, dateTime: `${new Date(posts[0]?.datetime * 1000).toLocaleDateString('sr-SR')}  |  ${new Date(posts[0]?.datetime*1000).toLocaleTimeString('sr-SR', {hour: '2-digit', minute: '2-digit'})}`}} newsImg={API_ENDPOINT + posts[0]?.images[0]}/></Link>}
                    <Row style={{marginBottom: '20px'}}>
                            {posts.length > 0 && posts.map((post, i) => {
                                if (i === 0) {
                                    return;
                                }
                                const ts = new Date(post?.datetime * 1000);
                                const date = ts.toLocaleDateString('sr-SR');
                                const time = ts.toLocaleTimeString('sr-SR', {hour: '2-digit', minute: '2-digit'});
                                const DATA = {
                                    heading: post?.title,
                                    category: post?.category,
                                    dateTime: `${date}  |  ${time}`
                                }
                                return <Col key={post._id} lg={6}><Link style={{textDecoration: 'none'}} to={`/vijesti/${post?.alias}`}><MagazineVerticalNews className='categoryVerticalNews' newsText={DATA} img={API_ENDPOINT + post?.images[0]} /></Link></Col>
                            }
                            )}
                        </Row>
                </Col>
                <Col lg={4}>
                    <LatestNewsColumn />
                    {/* <WhiteFrameBanner bannerImg={banner1} /> */}
                    {banner1 ? <Banner data={banner1} /> : <Placeholder h='300px' /> }
                    <PopularNewsColumn />
                    {/* <WhiteFrameBanner bannerImg={banner1} /> */}
                    {banner2 ? <Banner data={banner2} /> : <Placeholder h='300px' /> }
                    <PopularVideosColumn />
                </Col>
            </Row>
        </Container>
    </div>
}

export default Search;