import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`

    *{
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: "Open Sans", sans-serif;
        color: ${props => props.theme.darkColor}
    }

    body{
        background-color: ${props => props.theme.lightGreyColor}
    }
`

export default GlobalStyle