import React from 'react'
import styled from 'styled-components';
import { RowWrapper } from '../../styled/Layout.styled';

export const MainHeadingWord = styled.p`
    font-family: 'Staatliches', cursive; 
    font-size: 60px;
    color:  ${props => props.color === "red" ? props.theme.redColor : props.color === "black" ? props.theme.darkColor : ""};
   
    @media (max-width: 920px) {
        font-size: 50px;
    }

    @media (max-width: 540px) {
        font-size: 30px;
    }
`

export default function MainHeading({ redText, blackText, justify, className="main-heading" }) {
    return (
        <div className={className}>
            <RowWrapper justify={justify} >
                <MainHeadingWord color="red">{redText}</MainHeadingWord>
                <MainHeadingWord>{blackText}</MainHeadingWord>
            </RowWrapper>
        </div>
    )
}
